import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Spinner,
  // Tooltip
} from "reactstrap";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

//^ stylesheets
import classes from "./CurrentSubscription.module.scss";

//^ utils
import { getCookie } from "../../../../utils/Utils";
import { useTheme } from "../../../../layout/provider/Theme";

//^ http requests
import { getSubscriptedPackageHandler } from "../../../../http/post-api";

//^ ui
import Separator from "../../../../components/ui/separator/Separator";
import { Stack, Typography } from "@mui/material";

//^ components
import { Button, Icon } from "../../../../components/Component";
import ErrorAlert from "../../../../components/ui/modals/error-model/ErrorAlert";
import Head from "../../../../layout/head/Head";
import Switch from "../../../../components/ui/switch/Switch";

export function GreetingMessage({ username }) {
  return (
    <Stack gap={"0.625rem"}>
      <Typography variant="h5" textTransform={"capitalize"} fontWeight={600}>
        Hello {username}
      </Typography>
      <Typography variant="subtitle1">
        Welcome to {process.env.REACT_APP_PROJECT_NAME}, your safe place for all your digital stuff! Know that
        everything you give us, like files and ideas, is kept safe and ready for you to use whenever you want. Let's
        start creating without limits!
      </Typography>
    </Stack>
  );
}

export function PlanDetails({ packageName, services, price, tokenValue, augerPrice, endDate }) {
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  const [showSBCToken, setShowSBCToken] = useState(false);

  function handleClickShowSBCToken() {
    setShowSBCToken(!showSBCToken);
  }

  const formattedEndDate = moment(endDate).format("DD/MM/YYYY");

  const SBCPrice = price / tokenValue;
  const SBCAuger = augerPrice / tokenValue;

  return (
    <div>
      <div>
        <div className={`${classes["plan-details-main"]}`}>
          <div className={`${classes["package-plan"]} h-100 p-2`}>
            <h5 className={`${classes["package-name"]}`}>{packageName} package includes</h5>
            <div className={`${classes["package-services"]}`}>
              {services.map((service, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-start"
                  style={{ gap: "0.625rem" }}
                >
                  <em className={`${service?.service?.bs_icon}`} />
                  <p>{service.service.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={`h-100 p-2 ${isDark ? "" : "border"}`} style={{ borderRadius: "0.425rem" }}>
            <div className="d-flex flex-column" style={{ gap: "2.25rem" }}>
              <div className="text-center">
                <h5>Payment Details</h5>
              </div>
              <div className={`${classes["payment-details"]}`}>
                <Switch
                  size={"sm"}
                  checkedLabel={"USD"}
                  unCheckedLabel={"SBC"}
                  checked={!showSBCToken}
                  onClick={handleClickShowSBCToken}
                />
              </div>
              <div className={`${classes["payment-details-info"]}`}>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                  <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                    Price
                  </p>
                  <p className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                    {showSBCToken ? `SBC ${SBCPrice.toFixed(2)}` : `$${price}`}
                  </p>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                  <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                    Auger Fee
                  </p>

                  <p className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                    {showSBCToken ? `SBC ${SBCAuger.toFixed(2)}` : `$${augerPrice}`}
                  </p>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                  <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                    Subscription Validity
                  </p>

                  <p className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                    <span>{formattedEndDate}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={`${classes["px-1"]}`}>
              <Separator />
            </div>
            <div className={`${classes["px-1"]}`}>
              <div style={{ display: "grid", gridTemplateColumns: "repeat(9, 1fr)", width: "100%" }}>
                <p className={`${classes["h5"]}`} style={{ gridColumn: "span 8" }}>
                  Total Payment
                </p>
                <p className={`${classes["p"]}`} style={{ whiteSpace: "nowrap" }}>
                  {showSBCToken ? `SBC ${(SBCPrice + SBCAuger).toFixed(2)}` : `$ ${price + augerPrice}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CurrentSubscription() {
  const navigate = useNavigate();
  // const [tooltipPrintInvoiceOpen, setTooltipPrintInvoiceOpen] = useState(false);
  // const toggleTooltipPrintInvoice = () => setTooltipPrintInvoiceOpen(!tooltipPrintInvoiceOpen);

  const username = getCookie("username", undefined);

  const textMotion = {
    hover: {
      x: 3,
    },
  };

  const {
    data: getSubscribedData,
    isLoading: getSubscribedIsLoading,
    isError: getSubscribedIsError,
    error: getSubscribedError,
    refetch: getSubscribedRefetch,
  } = useQuery({
    queryKey: ["get-subscribed-user-plan"],
    queryFn: getSubscriptedPackageHandler,
  });

  useEffect(() => {
    if (!getSubscribedIsLoading) {
      if (!getSubscribedData.status) {
        navigate("/subscription-package");
      }
    }
  }, [getSubscribedData, getSubscribedIsLoading, navigate]);

  useEffect(() => {
    if (getSubscribedIsError) {
      navigate("/subscription-package");
    }
  }, [getSubscribedError, getSubscribedIsError, navigate]);

  function printInvoiceHandler() {
    navigate("print-invoice");
  }

  return (
    <>
      <Head title="Current Subscription" />
      {getSubscribedIsError && (
        <ErrorAlert
          title={"Something Went Wrong"}
          description={"Unable to get subscription plan."}
          isConformed={() => getSubscribedRefetch()}
        />
      )}

      {getSubscribedIsLoading ? (
        <div className={`d-flex justify-content-center align-items-center`} style={{ width: "100%", height: "50vh" }}>
          <Spinner />
        </div>
      ) : getSubscribedData.status ? (
        <>
          <div className="d-flex flex-column w-100" style={{ gap: "0.625rem" }}>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <h5>
                <span>{getSubscribedData.data.package.package_data.name} </span>
                <strong>Subscription</strong>
              </h5>
              <div id="print-invoice-tooltip d-none">
                <Button color="primary" type="button" className="d-none" onClick={printInvoiceHandler}>
                  <Icon name={"printer"} />
                </Button>
              </div>
              {/* <Tooltip
                placement="top"
                isOpen={tooltipPrintInvoiceOpen}
                target="print-invoice-tooltip"
                toggle={toggleTooltipPrintInvoice}
              >
                Print Invoice
              </Tooltip> */}
            </div>
            <Separator />
            <div className={classes["subscription-main"]}>
              <GreetingMessage username={username} />
              <PlanDetails
                packageName={getSubscribedData.data.package.package_data.name}
                services={getSubscribedData.data.package.package_data.services}
                price={getSubscribedData.data.package.price}
                augerPrice={getSubscribedData.data.package.auger_price}
                startDate={getSubscribedData.data.package.start_date}
                endDate={getSubscribedData.data.package.end_date}
                tokenValue={getSubscribedData.data.package.token_value}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="w-100 text-center d-flex justify-content-center" style={{ height: "100vh", width: "100%" }}>
          <div className="w-100">
            <div className={`rounded ${classes["unsubscribed-message"]}`}>
              <p className="text-danger" style={{ textTransform: "capitalize" }}>
                {getSubscribedData.message}
              </p>
              <motion.div whileHover={"hover"}>
                <Button outline color="light" onClick={() => navigate("/subscription-package")}>
                  <span className={`d-flex align-items-center`} style={{ gap: "0.625rem" }}>
                    <span>Buy Subscription</span>
                    <motion.span variants={textMotion} className="d-flex justify-content-center align-items-center">
                      <Icon name="arrow-right" />
                    </motion.span>
                  </span>
                </Button>
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
