import { useContext, useEffect } from "react";
import { UserContext } from "../pages/pre-built/user-manage/UserContext";
import { useDispatch } from "react-redux";
import { setPaymentSlice, setAccountToken } from "../store/slices/payment-slice";
import { useQuery } from "@tanstack/react-query";
import { getUserProfileHandler } from "../http/get-api";
import ErrorAlert from "../components/ui/modals/error-model/ErrorAlert";

const Profile = () => {
  const { setData } = useContext(UserContext);
  const dispatch = useDispatch();

  const {
    data: userProfileData,
    isLoading: userProfileIsLoading,
    isRefetching: userProfileIsRefetching,
    isError: userProfileIsError,
    error: userProfileError,
    refetch: userProfileRefetch,
  } = useQuery({
    queryKey: ["get-user-profile"],
    queryFn: async ({ signal }) => getUserProfileHandler({ signal }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!userProfileIsLoading || !userProfileIsRefetching) {
      if (!userProfileData?.status) {
        if (userProfileData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        var notifications = userProfileData?.data?.profile.notifications;
        setData({
          first_name: userProfileData?.data?.profile.first_name,
          last_name: userProfileData?.data?.profile.last_name,
          phone_number: userProfileData?.data?.profile.phone_number,
          dob: userProfileData?.data?.profile.dob,
          address_1: userProfileData?.data?.profile.address_1,
          address_2: userProfileData?.data?.profile.address_2,
          notifications: notifications,
          state: userProfileData?.data?.profile.state,
          country: userProfileData?.data?.profile.country || "US",
          country_value: userProfileData?.data?.profile.country_value || 231,
          account_tokens: userProfileData?.data?.account_tokens,
        });
        dispatch(setPaymentSlice({ users_account_tokens: userProfileData?.data?.account_tokens }));
        dispatch(setAccountToken(userProfileData?.data?.account_tokens));
      }
    }
    // eslint-disable-next-line
  }, [userProfileData, userProfileIsLoading, userProfileIsLoading, dispatch]);

  useEffect(() => {
    if (userProfileIsError) {
      console.log(userProfileError);
    }
  }, [userProfileIsError, userProfileError]);

  return (
    <>
      {userProfileIsError && (
        <ErrorAlert
          title={userProfileError?.code || 500}
          description={userProfileError?.info?.message || "Something went wrong"}
          isConformed={() => userProfileRefetch()}
        />
      )}
    </>
  );
};

export default Profile;
