import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useMutation } from "@tanstack/react-query";
import { Spinner, Tooltip } from "reactstrap";
import { useNavigate, useParams } from "react-router";

//^ utils
import { getCookie } from "../../../utils/Utils";

//^ http request
import { addPaymentHandler, buyWalletHandler, subscribeServiceHandler } from "../../../http/post-api";

//^ mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { queryClient } from "../../../http";

export default function ServicePayment({
  payment,
  accountToken,
  onPaymentModelClose,
  isPaymentOpen,
  totalPrice,
  tokenValue,
  augerPrice,
  serviceName,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const servicePlanValidity = useSelector((state) => state.servicePlan.servicePlanValidity);

  const { serviceId, planId } = useParams();

  //^ boolean states
  const [tooltipInfoOpen, setTooltipTwoFAOpen] = React.useState(false);
  const [paymentModel, setPaymentModel] = React.useState(isPaymentOpen || false);

  //^ other states
  const [inputValue, setInputValue] = React.useState({
    usd: parseFloat(totalPrice.toFixed(2)),
    account_tokens: payment / accountToken,
  });
  const [section, setSection] = React.useState("accountTokens");
  const [accountTokenData, setAccountTokenData] = useState(accountToken || 0.0);

  //^ add payment mutation query
  const {
    isPending: addPaymentIsPending,
    isError: addPaymentIsError,
    error: addPaymentError,
    mutate: addPaymentMutate,
    reset: addPaymentReset,
  } = useMutation({
    mutationKey: ["add-payment-query"],
    mutationFn: addPaymentHandler,
    onSuccess: (responseData) => {
      if (responseData.status) {
        purchaseToken(responseData.data.payment_id);
      } else {
        toast.error(responseData.message);
      }
      addPaymentReset();
    },
  });

  //^ buy wallet mutation query
  const {
    isPending: buyWalletIsPending,
    isError: buyWalletIsError,
    error: buyWalletError,
    mutate: buyWalletMutate,
    reset: buyWalletReset,
  } = useMutation({
    mutationKey: ["buy-wallet-query"],
    mutationFn: buyWalletHandler,
    onSuccess: (responseData) => {
      if (responseData.status) {
        toast.success(responseData.message);
        setAccountTokenData(responseData.data.account_tokens);
        setSection("accountTokens");
        subscribeServicePlan();
      } else {
        toast.error(responseData.message);
      }
      buyWalletReset();
    },
  });

  //^ subscribe package mutation query
  const {
    isPending: subscribePlanIsPending,
    isError: subscribePlanIsError,
    error: subscribePlanError,
    mutate: subscribeServiceMutate,
    reset: subscribeServiceReset,
  } = useMutation({
    mutationKey: ["subscribe-plan-service"],
    mutationFn: subscribeServiceHandler,
    onSuccess: (data) => {
      if (data.status) {
        toast.success(data.message);
        queryClient.invalidateQueries(["get-user-profile"]);
        queryClient.invalidateQueries(["get-user-profile-index-app"]);
        queryClient.invalidateQueries(["get-user-profile"]);
        queryClient.invalidateQueries(["get-user-profile-user-layout"]);
        navigate(`print-invoice/${data?.data?.invoice_id}`);
        onPaymentModelClose(false);
      } else {
        toast.error(data.message);
      }

      subscribeServiceReset();
    },
  });

  useEffect(() => {
    if (subscribePlanIsError) {
      console.log(subscribePlanError);
    }
  }, [subscribePlanIsError, subscribePlanError]);

  const subscribeServicePlan = async () => {
    subscribeServiceMutate({ validity: servicePlanValidity ? "12" : "1", plan_id: planId, service_id: serviceId });
  };

  useEffect(() => {
    if (buyWalletIsError) {
      console.log(buyWalletError);
    }
  }, [buyWalletIsError, buyWalletError]);

  useEffect(() => {
    if (addPaymentIsError) {
      console.log(addPaymentError?.info?.data?.message);
    }
  }, [addPaymentIsError, addPaymentError]);

  const toggleTooltipInfo = () => setTooltipTwoFAOpen(!tooltipInfoOpen);

  const handleClose = () => {
    onPaymentModelClose(false);
    setPaymentModel(false);
  };

  React.useEffect(() => {
    let tempusd = inputValue.usd || payment;
    setInputValue({ ...inputValue, account_tokens: tempusd / tokenValue });
    // eslint-disable-next-line
  }, []);

  const onError = (_data, _actions) => {
    toast.error("An Error occurred with your payment");
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      addPayment(details);
    });
  };

  const addPayment = async (payment_response) => {
    let formData = new FormData();
    formData.append("amount", payment_response?.purchase_units[0]?.amount?.value);
    formData.append("note", "package purchase");
    formData.append("payment_txn_id", payment_response?.purchase_units[0]?.payments?.captures[0]?.id);
    formData.append("payment_response", JSON.stringify(payment_response));
    formData.append("status", 3);
    formData.append("mode", 3);
    formData.append("purpose", 2);

    addPaymentMutate(formData);
  };

  const purchaseToken = async (responsePaymentId) => {
    if (responsePaymentId) {
      let formData = new FormData();
      formData.append("payment_id", responsePaymentId);

      buyWalletMutate(formData);
    } else {
      toast.error("Invalid payment id");
    }
  };

  const createOrder = (_data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Order",
            amount: {
              currency_code: "USD",
              value: parseFloat(inputValue.usd.toFixed(2)) || payment,
            },
            payee: {
              email_address: getCookie("email", "joe@example.com"),
              merchant_id: process.env.REACT_APP_PAYPAL_MERCHANT_ID,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  return (
    <>
      <Dialog
        open={paymentModel}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          sx: {
            width: "100%",
            maxWidth: "37.5rem",
          },
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>{`${serviceName} Purchase: $${totalPrice}`}</DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                name="payment-option"
                id="payment-option-paypal"
                data-payment-type="paypal"
                onClick={(e) => {
                  setInputValue({ ...inputValue, usd: parseFloat(totalPrice.toFixed(2)) });
                  e.target.checked && setSection("paypal");
                }}
                checked={section === "paypal"}
                control={<Radio />}
                label="Paypal"
              />
              <FormControlLabel
                name="payment-option"
                id="payment-option-account-tokens"
                data-payment-type="accountTokens"
                onClick={(e) => e.target.checked && setSection("accountTokens")}
                checked={section === "accountTokens"}
                control={<Radio />}
                label="Account Tokens"
              />
            </RadioGroup>
          </FormControl>

          {section !== "accountTokens" && (
            <Box sx={{ width: "100%", padding: "1.25rem" }}>
              <Stack gap={"1rem"} justifyContent={"center"} alignItems={"center"}>
                <Stack direction={"row"} gap={"0.325rem"}>
                  <Typography fontWeight={"600"}>{"Live Token Rate: 1 SBC"}</Typography>
                  <Typography>{`$${tokenValue.toFixed(6)}`}</Typography>
                </Stack>

                <Stack direction={"row"} gap={"0.325rem"}>
                  <Typography fontWeight={"600"}>{"Purchase Amount($):"}</Typography>
                  <Typography>{inputValue.usd}</Typography>
                </Stack>
              </Stack>
            </Box>
          )}

          <Stack alignItems={"center"} width={"100%"}>
            {section === "paypal" && (
              <>
                <div id="paypal-payment" className="col-md-4 pt-1 text-center">
                  <div id="paypal-button" className="m-auto">
                    <PayPalScriptProvider
                      options={{
                        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                      }}
                    >
                      {
                        <Stack direction={"row"} gap={"1.25rem"} alignItems={"center"} justifyContent={"center"}>
                          <Box>
                            <PayPalButtons
                              style={{ layout: "horizontal" }}
                              createOrder={createOrder}
                              onApprove={onApprove}
                              onError={onError}
                            />
                          </Box>
                          {addPaymentIsPending || buyWalletIsPending ? (
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                              <Spinner size={"sm"} style={{ borderWidth: "2px" }} />
                            </Stack>
                          ) : (
                            ""
                          )}
                        </Stack>
                      }
                    </PayPalScriptProvider>
                  </div>
                </div>
              </>
            )}
            {section === "accountTokens" && (
              <>
                <Box width={"100%"} padding={"1.25rem 0.625rem"}>
                  <Stack gap={"1rem"}>
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                          <Stack direction="row" gap="0.325rem">
                            <Typography fontWeight={"600"} whiteSpace={"nowrap"}>
                              Available Tokens:
                            </Typography>
                            <Typography>{accountTokenData.toFixed(6)}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Stack direction="row" gap="0.325rem">
                            <Typography fontWeight={"600"}>{"Auger Fee(2.9%):"}</Typography>
                            <Typography>{((payment * augerPrice) / 100 / tokenValue).toFixed(6)}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Stack direction={"row"} gap={"0.625rem"}>
                            <Typography>
                              <Typography fontWeight={"600"}>
                                <Stack direction={"row"} gap={"0.325rem"}>
                                  <span style={{ whiteSpace: "nowrap" }}>Required Tokens</span>
                                  <span id="required-token">
                                    <InfoOutlinedIcon fontSize="20" />
                                  </span>
                                  <span>:</span>
                                </Stack>
                              </Typography>
                              <Tooltip
                                placement="top"
                                isOpen={tooltipInfoOpen}
                                target="required-token"
                                toggle={toggleTooltipInfo}
                              >
                                Required tokens with Auger Fee
                              </Tooltip>
                            </Typography>
                            <Typography>
                              {((payment + (payment * augerPrice) / 100) / tokenValue).toFixed(6)}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </Box>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: theme.spacing(2) }}>
          {section === "accountTokens" && (
            <Box>
              {(payment + (payment * augerPrice) / 100) / tokenValue >= accountToken ? (
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    let tempUsdInputValue = parseFloat((payment + augerPrice).toFixed(2));
                    console.log(tempUsdInputValue);

                    setInputValue({ ...inputValue, usd: tempUsdInputValue });
                    setSection("paypal");
                  }}
                >
                  Purchase tokens
                </Button>
              ) : (
                <Box>
                  <Button
                    autoFocus
                    variant="contained"
                    type="button"
                    onClick={() => subscribeServicePlan()}
                    disabled={subscribePlanIsPending}
                    startIcon={subscribePlanIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px" }} /> : ""}
                  >
                    Pay with Tokens
                  </Button>
                </Box>
              )}
            </Box>
          )}
          <Button variant="contained" type="button" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
