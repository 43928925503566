//^ mui
import { Stack, Skeleton, Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

//^ components
import Separator from "../../separator/Separator";

export default function SubscriptionPackageSkeleton() {
  return (
    <Stack height={"100%"} width={"100%"} spacing={2} direction={{ xs: "column", md: "row" }}>
      {Array.from({ length: 3 }).map((_, index) => (
        <Paper
          key={index}
          sx={{ width: "100%", height: "100%", borderRadius: "1rem", padding: "1.25rem" }}
          elevation={4}
        >
          <Stack gap={"2rem"} height={"100%"}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Skeleton variant="rounded" width={"10rem"} animation="wave" />
              <Skeleton animation="wave" variant="rounded" sx={{ width: "112px", height: "111px" }} />
            </Stack>
            <Stack justifyContent={"space-between"} height={"30rem"}>
              <Stack>
                <Stack direction={"row"} className="amount" gap={"0.325rem"}>
                  <Skeleton animation="wave" variant="rounded" width={"2rem"} height={"1.55rem"} />
                  <Stack alignItems={"flex-end"} direction={"row"} width={"100%"} gap={"0.225rem"}>
                    <Typography>{`/ `}</Typography>
                    <Skeleton animation="wave" variant="rounded" width={"1.5rem"} height={"1rem"} />
                  </Stack>
                </Stack>
                <Separator />
                <Stack gap={"3rem"}>
                  <Box>
                    <Skeleton animation="wave" variant="rounded" width={"40%"} height={"1.25rem"} />
                  </Box>
                  <Stack gap={"1.5rem"} width={"100%"}>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <>
                        <Stack key={index} direction={"row"} gap={"0.625rem"}>
                          <Skeleton animation="wave" variant="rounded" width={"20%"} height={"1.25rem"} />
                          <Skeleton animation="wave" variant="rounded" width={"100%"} height={"1.25rem"} />
                        </Stack>
                      </>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
              <Stack alignItems={"center"}>
                <Skeleton animation="wave" variant="rounded" width={"8rem"} height={"2.5rem"} />
              </Stack>
            </Stack>
          </Stack>
          {/* <Skeleton
            variant="rounded"
            width={"100%"}
            sx={{ width: "100%", borderRadius: "0.625rem" }}
            height={"30rem"}
            animation="wave"
          /> */}
        </Paper>
      ))}
    </Stack>
  );
}
