import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

//^ stylesheets
import classes from "./SubscriptionPackage.module.scss";

//^ mui
import { Paper } from "@mui/material";

//^ http request
import { getPackagesSubscriptionHandler } from "../../../../http/get-api";
import { getSubscriptedPackageHandler } from "../../../../http/post-api";

//^ redux slice
import {
  setAugerFee,
  setPlan,
  setPlanList,
  setPurchase,
  setAccountTokens,
  setTokenValue,
  setValidity,
} from "../../../../store/slices/package-subscription-slice";

//^ components
import Head from "../../../../layout/head/Head";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, Icon } from "../../../../components/Component";
import ErrorAlert from "../../../../components/ui/modals/error-model/ErrorAlert";
import Separator from "../../../../components/ui/separator/Separator";
import Switch from "../../../../components/ui/switch/Switch";
import { Box, Stack, Button, Typography } from "@mui/material";
import SubscriptionPackageSkeleton from "../../../../components/ui/loader/Skeleton/SubscriptionPackageSkeleton";
import Title from "../../../../components/title/Title";

export default function SubscriptionPackage() {
  const planList = useSelector((store) => store.packageSubscription.planList);
  const validity = useSelector((store) => store.packageSubscription.validity);

  const [subscribedPackagePrice, setSubscribedPackagePrice] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: getPackagesData,
    isLoading: getPackagesIsLoading,
    isError: getPackagesIsError,
    refetch: getPackagesRefetch,
  } = useQuery({
    queryKey: ["get-subscription-package-lists"],
    queryFn: ({ signal }) => getPackagesSubscriptionHandler({ signal }),
  });

  //^ getting current user's subscription package details query request
  const {
    data: getUserPackageData,
    isLoading: getUserPackageIsLoading,
    isRefetching: getUserPackageIsRefetching,
    isError: getUserPackageIsError,
    error: getUserPackageError,
    refetch: getUserPackageRefetch,
  } = useQuery({
    queryKey: ["get-current-user-package-details"],
    queryFn: getSubscriptedPackageHandler,
  });

  useEffect(() => {
    if (!getUserPackageIsLoading || !getUserPackageIsRefetching) {
      if (!getUserPackageData?.status) {
        if (getUserPackageData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setSubscribedPackagePrice(getUserPackageData?.data?.package?.price);
      }
    }
  }, [getUserPackageData, getUserPackageIsLoading, getUserPackageIsRefetching]);

  useEffect(() => {
    if (getUserPackageIsError) {
      console.log(getUserPackageError);
    }
  }, [getUserPackageError, getUserPackageIsError]);

  useEffect(() => {
    if (!getPackagesIsLoading) {
      if (getPackagesData?.status) {
        let tempArr = [];

        getPackagesData?.data?.packages.map((pkg) => {
          const tempObj = {
            id: pkg.id,
            title: pkg.name,
            logo: `${process.env.REACT_APP_API_URL}${pkg.logo}`,
            desc: pkg.description,
            monthly_price: pkg.monthly_price,
            yearly_price: pkg.yearly_price,
            services: pkg.services,
            is_subscribed: pkg.is_subscribed,
            subscription_validity: pkg.subscription_validity,
          };
          tempArr.push(tempObj);
          return null;
        });

        dispatch(setPlanList(tempArr));
        dispatch(setAugerFee(getPackagesData?.data?.auger_fee));
        dispatch(setTokenValue(getPackagesData?.data?.token_value));
        dispatch(setAccountTokens(getPackagesData?.data?.account_tokens));
      }
    }
  }, [getPackagesData, getPackagesIsLoading, dispatch]);

  const validityClick = useCallback(
    (isChecked) => {
      if (isChecked) {
        dispatch(setValidity(true));
      } else {
        dispatch(setValidity(false));
      }
    },
    [dispatch]
  );

  const selectPlan = (plan) => {
    dispatch(setPurchase(true));
    dispatch(setPlan(plan));
    navigate(`/subscription-package-details/${encodeURI(plan.id)}`);
  };

  const textMotion = {
    hover: {
      x: 3,
    },
  };

  return (
    <>
      {getUserPackageIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to get the current user's subscription package.`}
          isConformed={() => getUserPackageRefetch()}
        />
      )}
      {getPackagesIsError && (
        <ErrorAlert
          title={"Something went wrong"}
          description={`Unable to get the subscription package details.`}
          isConformed={() => getPackagesRefetch()}
        />
      )}
      <Head title="Subscriptions" />
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent className={"w-100"}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Title title={"Package Subscription"} divider={false} />
              <Box>
                <Switch checkedLabel={"Monthly"} unCheckedLabel={"Yearly"} onClick={validityClick} checked={validity} />
              </Box>
            </Stack>
            <Separator />
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <>
        <div className={`${classes["subscription-packages-list"]} pb-4`}>
          {getPackagesIsLoading || getUserPackageIsLoading ? (
            <div className="d-flex w-100" style={{ height: "100%", gridColumn: "span 3" }}>
              <Box width={"100%"}>
                <SubscriptionPackageSkeleton />
              </Box>
            </div>
          ) : (
            planList?.map((plan) => {
              const planPrice = !validity ? plan.monthly_price : plan.yearly_price;
              const validityString = validity ? "12" : "1";
              const subscriptionValidity = validityString === plan?.subscription_validity;

              return (
                <>
                  <div
                    className={`${
                      getUserPackageData?.status
                        ? plan.is_subscribed &&
                          plan.subscription_validity === validityString &&
                          plan.id === getUserPackageData.data.package.package_id &&
                          "bg-primary d-flex flex-column"
                        : ""
                    }`}
                    style={
                      getUserPackageData?.status
                        ? plan.is_subscribed &&
                          plan.subscription_validity === validityString &&
                          plan.id === getUserPackageData.data.package.package_id
                          ? {
                              borderRadius: "1rem",
                              paddingRight: "0.225rem",
                              paddingLeft: "0.225rem",
                              paddingBottom: "0.225rem",
                            }
                          : {}
                        : {}
                    }
                  >
                    {getUserPackageData?.status ? (
                      plan.is_subscribed &&
                      plan.subscription_validity === validityString &&
                      plan.id === getUserPackageData.data.package.package_id ? (
                        <Typography
                          textAlign={"center"}
                          variant="overline"
                          fontWeight={"600"}
                          color={"white"}
                          fontSize={15}
                          padding={"0.725rem 0rem"}
                        >
                          Current Subscription
                        </Typography>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    <Paper
                      elevation={6}
                      className={`pricing ${plan.tags ? "recommend" : ""} pb-2 position-relative`}
                      sx={{ borderRadius: "1rem" }}
                    >
                      <div className={`p-2 ${classes["pricing-details"]}`}>
                        <div className="d-flex flex-column" style={{ gap: "1.25rem" }}>
                          <div className={`${classes["subscription-title"]}`}>
                            <div>
                              <h5 className="title">{plan.title}</h5>
                              <span className="sub-text">{plan.desc}</span>
                            </div>
                            <div
                              className={`${
                                getUserPackageData?.status
                                  ? plan.id === getUserPackageData.data.package.package_id
                                    ? "pt-2"
                                    : ""
                                  : ""
                              }`}
                              style={{ width: "7rem", height: "7rem" }}
                            >
                              <img src={plan.logo} alt={plan.title} />
                            </div>
                          </div>
                          <div className="pricing-amount px-2">
                            <div className="amount">
                              ${!validity ? plan.monthly_price : plan.yearly_price}{" "}
                              <span style={{ fontSize: "12px" }}>{`/ ${!validity ? "mo" : "yr"}`}</span>
                            </div>
                            <Separator />

                            <div>
                              <p>Includes</p>
                              <p className="d-flex flex-column fs-5" style={{ gap: "1rem" }}>
                                {plan && plan.services && plan?.services?.length > 0
                                  ? plan.services.map((service, index) => {
                                      return (
                                        <span
                                          key={index}
                                          className="d-flex align-items-center"
                                          style={{ gap: "0.625rem" }}
                                        >
                                          {service.include ? (
                                            <Icon name="check-thick" className={"text-success"} />
                                          ) : (
                                            <Icon name="cross" className={"text-danger fw-bold"} />
                                          )}

                                          <BlockDes>{service.name}</BlockDes>
                                        </span>
                                      );
                                    })
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="pricing-action d-flex justify-content-center">
                          <motion.div whileHover={"hover"}>
                            <Button variant="contained" color="primary" size="large" onClick={() => selectPlan(plan)}>
                              <span className={`d-flex align-items-center`} style={{ gap: "0.625rem" }}>
                                <Typography variant="subtitle2" component={"span"}>
                                  {!subscriptionValidity
                                    ? getUserPackageData?.status
                                      ? plan.id === getUserPackageData.data.package.package_id && subscriptionValidity
                                        ? "Renew"
                                        : getUserPackageData?.status
                                        ? subscribedPackagePrice > planPrice
                                          ? "Buy Subscription"
                                          : "Upgrade Subscription"
                                        : "Buy Now"
                                      : "Buy Now"
                                    : "Renew Now"}
                                </Typography>
                                <motion.span
                                  variants={textMotion}
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <Icon name="arrow-right" />
                                </motion.span>
                              </span>
                            </Button>
                          </motion.div>
                        </div>
                      </div>
                    </Paper>
                  </div>
                </>
              );
            })
          )}
        </div>
      </>
    </>
  );
}
