import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileData: null,
  userWholeProfileData: {
    account_tokens: 0,
  },
};

const userProfileSlice = createSlice({
  initialState,
  name: "user-profile",
  reducers: {
    setProfileData: (state, { payload }) => {
      state.profileData = payload.profileData;
    },
    setWholeProfileData: (state, { payload }) => {
      state.userWholeProfileData = payload.wholeProfileData;
    },
  },
});

export const userProfileAction = userProfileSlice.actions;

export default userProfileSlice.reducer;
