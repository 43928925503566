import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { usePDF } from "react-to-pdf";

//^ layouts
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { useTheme } from "../../layout/provider/Theme";

//^ images
import LogoDark from "../../images/logo-dark.png";

//^ muiAction slices
import { muiAction } from "../../store/slices/mui-slice";

//^ http requests
import { getServiceInvoiceHandler } from "../../http/post-api";

//^ mui
import { Button, Container, Stack, Typography } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

//^ components
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import ErrorAlert from "../../components/ui/modals/error-model/ErrorAlert";
import { sumArray } from "../../utils/Utils";

//^ Error Model
import Error404Modern from "../error/404-modern";
import SubscriptionInvoicesSkeleton from "../../components/ui/loader/Skeleton/SubscriptionInvoicesSkeleton";

export default function ServiceInvoiceComp() {
  const [invoiceData, setInvoiceData] = useState(undefined);
  const [subTotal, setSubTotal] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [showBtn, setShowBtn] = useState(true);

  const { toPDF, targetRef } = usePDF({ filename: `${invoiceData?.txn_id}-invoice.pdf` });

  const theme = useTheme();
  const muiTheme = useMuiTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceId } = useParams();

  const { serviceInvoiceId } = useParams();

  const {
    data: getServiceInvoiceData,
    isLoading: getServiceInvoiceIsLoading,
    isRefetching: getServiceInvoiceIsRefetching,
    isError: getServiceInvoiceIsError,
    error: getServiceInvoiceError,
    refetch: getServiceInvoiceRefetch,
  } = useQuery({
    queryKey: ["get-service-invoice-by-id", serviceInvoiceId],
    queryFn: async () => getServiceInvoiceHandler({ invoiceId: parseInt(serviceInvoiceId) }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getServiceInvoiceIsLoading || !getServiceInvoiceIsRefetching) {
      if (!getServiceInvoiceData?.status) {
        if (getServiceInvoiceData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setInvoiceData(getServiceInvoiceData?.data?.invoice);
        const servicePrices = getServiceInvoiceData?.data?.invoice?.services?.map((service) => service.price);
        const sumServicePrices = sumArray(servicePrices).toFixed(2);
        const price = getServiceInvoiceData?.data?.invoice?.price;
        const augerPrice = getServiceInvoiceData?.data?.invoice?.auger_price;

        setSubTotal(sumServicePrices);
        setDiscountPrice((((sumServicePrices - price) / sumServicePrices) * 100).toFixed(2));
        setGrandTotal(parseFloat(price + augerPrice).toFixed(2));
      }
    }
  }, [getServiceInvoiceData, getServiceInvoiceIsLoading, getServiceInvoiceIsRefetching]);

  useEffect(() => {
    if (getServiceInvoiceIsError) {
      console.log(getServiceInvoiceError);
    }
  }, [getServiceInvoiceIsError, getServiceInvoiceError]);

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", "#ccc");
      document.documentElement.style.setProperty("--track-color", "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  useEffect(() => {
    if (!showBtn) {
      toPDF();
      setShowBtn(true);
    }
    // eslint-disable-next-line
  }, [showBtn]);

  return (
    <>
      <Head
        title={`${
          getServiceInvoiceIsLoading || getServiceInvoiceIsRefetching ? "Loading" : `${invoiceData?.txn_id}-invoice`
        }`}
      />
      {getServiceInvoiceIsError && (
        <ErrorAlert
          title={`${getServiceInvoiceError?.code || 500}`}
          description={`${getServiceInvoiceError?.info?.message || "Something went wrong"}`}
          isConformed={() => getServiceInvoiceRefetch()}
        />
      )}
      {getServiceInvoiceData && !getServiceInvoiceData.status && getServiceInvoiceData.code === 500 ? (
        <Error404Modern
          title="Invoice Not Found"
          description="We apologize for the inconvenience because there is no invoice related to you."
          backLink="/user-profile-regular"
        />
      ) : getServiceInvoiceIsLoading || getServiceInvoiceIsRefetching ? (
        <SubscriptionInvoicesSkeleton />
      ) : (
        <>
          <div ref={targetRef} style={{ background: showBtn ? "#f5f6fa" : "#fff" }}>
            <Content style={{ background: "#f5f6fa" }}>
              <BlockHead style={{ background: "#ffffff" }}>
                <BlockBetween className="g-3" style={{ background: "#ffffff" }}>
                  <BlockHeadContent style={{ background: "#ffffff" }}>
                    <BlockTitle style={{ background: "#ffffff" }}>
                      <span style={{ color: "#414c62" }}>Invoice</span>{" "}
                      <strong className="text-primary small">#{invoiceData?.txn_id}</strong>
                    </BlockTitle>
                    <BlockDes className="text-soft" style={{ background: "#ffffff" }}>
                      <ul className="list-inline">
                        <li>
                          Created At:{" "}
                          <span style={{ color: "#414c62" }}>{moment(invoiceData?.date).format("DD MMM YYYY")}</span>
                        </li>
                      </ul>
                    </BlockDes>
                  </BlockHeadContent>
                  {showBtn ? (
                    <BlockHeadContent
                      style={{ background: "#ffffff" }}
                      className={"d-flex justify-content-end align-items-end"}
                    >
                      <Stack direction={"row"} gap={"1rem"}>
                        <Button
                          size="lg"
                          variant="contained"
                          onClick={() => {
                            setShowBtn(false);
                          }}
                          startIcon={<PrintIcon />}
                        >
                          Print
                        </Button>
                        <Button
                          startIcon={<KeyboardBackspaceIcon />}
                          onClick={() => navigate(`/services/service-plan/renew/${serviceId}`)}
                          variant="contained"
                        >
                          <span>Back</span>
                        </Button>
                      </Stack>
                    </BlockHeadContent>
                  ) : (
                    ""
                  )}
                </BlockBetween>
              </BlockHead>
              <Block style={{ background: "#ffffff" }}>
                <Stack gap={"4rem"}>
                  <Stack justifyContent={"center"} alignItems={"center"}>
                    <Container>
                      <div style={{ background: "#ffffff", borderColor: "#ccc" }} className="invoice-wrap w-100">
                        <Stack alignItems={"center"} justifyContent={"center"} direction={"row"} width={"100%"}>
                          <img src={LogoDark} alt={invoiceData?.txn_id} style={{ width: "8rem", height: "8rem" }} />
                        </Stack>

                        <div className="invoice-head">
                          <div className="invoice-contact">
                            <Typography
                              variant="overline"
                              sx={{
                                fontWeight: "600",
                                color: "#414c62",
                              }}
                            >
                              Invoice To
                            </Typography>
                            <div className="invoice-contact-info">
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  color: "#414c62",
                                  textTransform: "capitalize",
                                  fontSize: "34px",
                                }}
                              >
                                {invoiceData?.username}
                              </Typography>
                              <ul className="list-plain">
                                <li>
                                  <Stack direction={"row"} alignItems={"center"}>
                                    <Icon name="mail-fill" />
                                    <Typography component={"span"}>{invoiceData?.email}</Typography>
                                  </Stack>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="invoice-desc">
                            <h3 className="title">Invoice</h3>
                            <ul className="list-plain">
                              <li>
                                <Typography
                                  variant="overline"
                                  sx={{
                                    fontWeight: "600",
                                    color: "#414c62",
                                  }}
                                >
                                  Invoice ID
                                </Typography>
                                :
                                <Typography variant="subtitle2" component={"span"}>
                                  {invoiceData?.txn_id}
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  variant="overline"
                                  sx={{
                                    fontWeight: "600",
                                    color: "#414c62",
                                  }}
                                >
                                  Date
                                </Typography>
                                :
                                <Typography variant="subtitle2" component={"span"}>
                                  {moment(invoiceData?.date).format("DD MMM YYYY")}
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  variant="overline"
                                  sx={{
                                    fontWeight: "600",
                                    color: "#414c62",
                                  }}
                                >
                                  End Date
                                </Typography>
                                :
                                <Typography variant="subtitle2" component={"span"}>
                                  {moment(invoiceData?.end_date).format("DD MMM YYYY")}
                                </Typography>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="invoice-bills" style={{ background: "#ffffff" }}>
                          <div className="table-responsive" style={{ background: "#ffffff" }}>
                            <table className="table table-striped" style={{ background: "#ffffff" }}>
                              <thead style={{ background: "#ffffff" }}>
                                <tr style={{ background: "#ffffff" }}>
                                  <th style={{ background: "#ffffff" }} className="w-150px">
                                    Item ID
                                  </th>
                                  <th style={{ background: "#ffffff" }} className="w-60">
                                    Service Name
                                  </th>
                                  <th style={{ background: "#ffffff" }}>Price</th>
                                  <th style={{ background: "#ffffff" }}>Qty</th>
                                  <th style={{ background: "#ffffff" }}>Amount</th>
                                </tr>
                              </thead>
                              <tbody style={{ background: "#ffffff" }}>
                                {invoiceData?.services.map((service, index) => {
                                  let count = index;
                                  const qty = service?.qty ? service?.qty : 1;
                                  count++;

                                  return (
                                    <tr style={{ background: "#ffffff" }} key={index}>
                                      <td style={{ background: "#ffffff" }}>{count}</td>
                                      <td style={{ background: "#ffffff" }}>{service?.name}</td>
                                      <td style={{ background: "#ffffff" }}>${service?.price}</td>
                                      <td style={{ background: "#ffffff" }}>{qty}</td>
                                      <td style={{ background: "#ffffff" }}>${(service?.price * qty).toFixed(2)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              <tfoot style={{ background: "#ffffff" }}>
                                <tr style={{ background: "#ffffff" }}>
                                  <td style={{ background: "#ffffff" }} colSpan="2"></td>
                                  <td style={{ background: "#ffffff" }} colSpan="2">
                                    Subtotal
                                  </td>
                                  <td style={{ background: "#ffffff" }}>{`$${subTotal}`}</td>
                                </tr>
                                <tr style={{ background: "#ffffff" }}>
                                  <td style={{ background: "#ffffff" }} colSpan="2"></td>
                                  <td style={{ background: "#ffffff" }} colSpan="2">
                                    Discount
                                  </td>
                                  <td style={{ background: "#ffffff" }}>{`${discountPrice}%`}</td>
                                </tr>
                                <tr style={{ background: "#ffffff" }}>
                                  <td style={{ background: "#ffffff" }} colSpan="2"></td>
                                  <td style={{ background: "#ffffff" }} colSpan="2">
                                    Discount Price
                                  </td>
                                  <td style={{ background: "#ffffff" }}>{`$${parseFloat(
                                    getServiceInvoiceData?.data?.invoice?.price
                                  ).toFixed(2)}`}</td>
                                </tr>
                                <tr style={{ background: "#ffffff" }}>
                                  <td style={{ background: "#ffffff" }} colSpan="2"></td>
                                  <td style={{ background: "#ffffff" }} colSpan="2">
                                    Auger Price
                                  </td>
                                  <td style={{ background: "#ffffff" }}>{`$${parseFloat(
                                    getServiceInvoiceData?.data?.invoice?.auger_price
                                  ).toFixed(2)}`}</td>
                                </tr>
                                <tr style={{ background: "#ffffff" }}>
                                  <td style={{ background: "#ffffff" }} colSpan="2"></td>
                                  <td style={{ background: "#ffffff" }} colSpan="2">
                                    Grand Total
                                  </td>
                                  <td style={{ background: "#ffffff" }}>{`$${grandTotal}`}</td>
                                </tr>
                              </tfoot>
                            </table>
                            <div className="nk-notes ff-italic fs-12px text-soft">
                              Invoice was created on a computer and is valid without the signature and seal.
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </Stack>
                </Stack>
              </Block>
            </Content>
          </div>
        </>
      )}
    </>
  );
}
