import React, { useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";

//^ utils
import { getCookie } from "../../../../utils/Utils";

//^ components
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { appLinks } from "./user-list";
import AccountAvatar from "../../../../pages/pre-built/user-manage/AccountAvatar";
import { Stack } from "@mui/material";

const User = () => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <AccountAvatar profileName={getCookie("username", "John")} />

        {/* <UserAvatar icon="user-alt" className="sm" /> */}
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <Stack direction={"row"} alignItems={"center"} gap={"1rem"}>
            <AccountAvatar profileName={getCookie("username", "John")} />
            <div className="user-info">
              <span className="lead-text">{getCookie("username", "John")}</span>
              <span className="sub-text">{getCookie("email", "john@mail.io")}</span>
            </div>
          </Stack>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* <LinkItem link="/user-profile-regular" icon="user-alt" onClick={toggle} className="d-none">
              View Profile
            </LinkItem> */}

            {appLinks.map((link, index) => {
              if (link.showLink) {
                return (
                  <React.Fragment key={index}>
                    <LinkItem link={link.link} icon={link.icon} onClick={toggle}>
                      {link.text}
                    </LinkItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    <LinkItem
                      linkClassName={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                      onClick={(ev) => {
                        ev.preventDefault();
                        themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                      }}
                    >
                      {theme.skin === "dark" ? (
                        <>
                          <div className="d-flex align-items-center">
                            <Icon name={link.lightIcon} />
                            <span>{link.textLight}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex align-items-center">
                            <Icon name={link.darkIcon} />
                            <span>{link.textDark}</span>
                          </div>
                        </>
                      )}
                    </LinkItem>
                  </React.Fragment>
                );
              }
            })}

            {/* <LinkItem link="/user-profile-activity" icon="activity-alt" className="d-none" onClick={toggle}>
              Login Activity
            </LinkItem> */}
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a href={`${process.env.REACT_APP_ACCOUNT_URL}logout`}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
