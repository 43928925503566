import { Stack, Skeleton, Box } from "@mui/material";
import Separator from "../../separator/Separator";

export default function OutletSkeleton() {
  return (
    <Stack width={"100%"}>
      <Box padding={"0rem 1.25rem"}>
        <Stack spacing={2}>
          <Skeleton variant="text" height={"4.25rem"} animation="wave" />
          <Separator />
        </Stack>
      </Box>
      <Box padding={"1.25rem"}>
        <Stack spacing={1} gap={4}>
          <Stack gap={1}>
            <Skeleton variant="text" height={"4.25rem"} width={"100%"} animation="wave" />
            <Skeleton variant="text" height={"3.25rem"} width={"90%"} animation="wave" />
            <Skeleton variant="text" height={"2.25rem"} width={"80%"} animation="wave" />
            <Skeleton variant="text" height={"2.25rem"} width={"70%"} animation="wave" />
            <Skeleton variant="text" height={"2.25rem"} width={"40%"} animation="wave" />
            <Skeleton variant="text" height={"1.85rem"} width={"50%"} animation="wave" />
          </Stack>
          <Stack gap={2}>
            <Skeleton variant="text" width={"60%"} animation="wave" />
            <Skeleton variant="text" width={"80%"} animation="wave" />
            <Skeleton variant="text" width={"70%"} animation="wave" />
            <Skeleton variant="text" width={"60%"} animation="wave" />
            <Skeleton variant="text" width={"50%"} animation="wave" />
            <Skeleton variant="text" width={"40%"} animation="wave" />
            <Skeleton variant="text" width={"30%"} animation="wave" />
            <Skeleton variant="text" width={"20%"} animation="wave" />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
