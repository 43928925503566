import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "reactstrap";
import DatePicker from "react-datepicker";
import { Modal, ModalBody } from "reactstrap";

//^ lib
import Head from "../../../layout/head/Head";

//^ components
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Icon,
  Row,
  Col,
  RSelect,
} from "../../../components/Component";

import { Button } from "@mui/material";

import { countryOptions } from "./UserData";
import { getCookie, getDateStructured } from "../../../utils/Utils";
import { UserContext } from "./UserContext";
import { toast } from "react-toastify";
import { fetchProfileAPI } from "../../../controller/profile_controller";

import Title from "../../../components/title/Title";

const UserProfileRegularPage = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [loading, setLoading] = useState(false);
  const { loggedInUser, setData } = useContext(UserContext);
  const [modalTab, setModalTab] = useState("1");
  const [countries, setCountries] = useState(countryOptions);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    getCountries();
  }, []);

  const onlyIntegerInputCheck = (e) => {
    const NUMBER_ONLY_REGEX = /^[0-9]+$/;
    let keyArray = [8, 13, 37, 39, 46, 9];
    if (!NUMBER_ONLY_REGEX.test(e.key) && !keyArray.includes(e.keyCode)) {
      e.preventDefault();
    }
  };
  const onInputChange = (e) => {
    if (e.target.name === "phone_number") {
      const regex = /^\d{0,13}$/;
      const isValid = regex.test(e.target.value);
      if (isValid) {
        setData({ ...loggedInUser, [e.target.name]: e.target.value });
      }
    } else {
      setData({ ...loggedInUser, [e.target.name]: e.target.value });
    }
  };
  const submitUserInfoForm = async () => {
    setLoading(true);
    let error = false;
    let phone_number_txt = loggedInUser.phone_number === null ? "" : loggedInUser.phone_number.toString();
    let value = phone_number_txt.replace(/^\s+|\s+$/gm, "");
    console.log(loggedInUser);
    if (loggedInUser.first_name === null || loggedInUser.first_name.trim().length === 0) error = "Enter first name";
    else if (loggedInUser.last_name === null || loggedInUser.last_name.trim().length === 0) error = "Enter last name";
    else if (value.length < 9 || value.length > 13) error = "Enter 9-13 digit phone number";

    if (error) {
      setLoading(false);
      toast.error(error);
      return false;
    }

    let dobDT = loggedInUser["dob"];
    let dob_dt = new Date(dobDT);
    if (isNaN(dob_dt.getTime())) error = "Enter valid date";

    let month = dob_dt.getMonth() < 9 ? "0" + (dob_dt.getMonth() + 1) : dob_dt.getMonth() + 1;

    let setProfileData = new FormData();
    setProfileData.append("first_name", loggedInUser.first_name);
    setProfileData.append("last_name", loggedInUser.last_name);
    setProfileData.append("phone_number", loggedInUser.phone_number);
    setProfileData.append("dob", `${dob_dt.getFullYear()}-${month}-${dob_dt.getDate()}`);

    let res = await fetchProfileAPI(setProfileData, "account/set-profile");

    if (res.status) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setModal(false);
    setLoading(false);
  };
  const submitAddressForm = async () => {
    setLoading(true);
    console.log(loggedInUser);
    let setProfileData = new FormData();
    setProfileData.append("address_1", loggedInUser.address_1);
    setProfileData.append("address_2", loggedInUser.address_2);
    setProfileData.append("state", loggedInUser.state);
    setProfileData.append(
      "country_value",
      loggedInUser.country_value !== "undefined" ? loggedInUser.country_value : 231
    );

    let res = await fetchProfileAPI(setProfileData, "account/set-profile");

    if (res.status) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setModal(false);
    setLoading(false);
  };
  const getCountries = async () => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`);
    res = await res.json();
    if (res.status) {
      let tempArr = [];
      res?.data?.countries.map((c) => {
        let value = c.id;
        let label = c.name;
        let country = { value, label };
        tempArr.push(country);
        // eslint-disable-next-line
        return;
      });
      setCountries(tempArr);
    }
  };
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  const checkInputLength = (e, length) => {
    let text = e.target.value;
    let keyArray = [8, 13, 37, 39, 46, 9];
    if (text.length >= length && !keyArray.includes(e.keyCode)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <React.Fragment>
        <Head title="User Profile" />

        {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent className={"w-100"}>
              <Title title={"Personal Information"} />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Profile Insights</h6>
            </div>
            <div className="data-item" onClick={() => setModal(true)}>
              <div className="data-col">
                <span className="data-label">First Name</span>
                <span className="data-value">{loggedInUser.first_name}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
            <div className="data-item" onClick={() => setModal(true)}>
              <div className="data-col">
                <span className="data-label">Last Name</span>
                <span className="data-value">{loggedInUser.last_name}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios" />
                </span>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Email</span>
                <span className="data-value">{getCookie("email", "john@doe.com")}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more disable">
                  <Icon name="lock-alt"></Icon>
                </span>
              </div>
            </div>
            <div className="data-item" onClick={() => setModal(true)}>
              <div className="data-col">
                <span className="data-label">Phone Number</span>
                <span className="data-value text-soft">{loggedInUser.phone_number}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
            <div className="data-item" onClick={() => setModal(true)}>
              <div className="data-col">
                <span className="data-label">Date of Birth</span>
                <span className="data-value">{loggedInUser.dob}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
            <div className="data-item" onClick={() => setModal(true)}>
              <div className="data-col">
                <span className="data-label">Address</span>
                <span className="data-value">
                  {loggedInUser.address_1 != null && loggedInUser.address_1 !== "null"
                    ? loggedInUser.address_1?.length > 0
                      ? loggedInUser.address_1
                      : loggedInUser.address_1 === "undefined"
                      ? "---"
                      : loggedInUser.address_1
                    : ""}
                  &nbsp;
                  {loggedInUser.address_2 != null && loggedInUser.address_2 !== "null"
                    ? loggedInUser.address_2?.length > 0
                      ? loggedInUser.address_2
                      : loggedInUser.address_2 === "undefined"
                      ? "---"
                      : loggedInUser.address_2
                    : ""}
                  <br />
                  {loggedInUser.state != null && loggedInUser.state !== "null"
                    ? loggedInUser.state
                    : loggedInUser.state === "undefined"
                    ? "---"
                    : loggedInUser.state}
                  &nbsp;
                  {loggedInUser.country != null && loggedInUser.country !== "null"
                    ? loggedInUser.country === undefined
                      ? "---"
                      : loggedInUser.country === "undefined"
                      ? "---"
                      : loggedInUser.country
                    : ""}
                </span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="forward-ios"></Icon>
                </span>
              </div>
            </div>
          </div>
          <div className="nk-data data-list d-none">
            <div className="data-head">
              <h6 className="overline-title">Preferences</h6>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Language</span>
                <span className="data-value">English (United State)</span>
              </div>
              <div className="data-col data-col-end">
                <a
                  href="#language"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="link link-primary"
                >
                  Change Language
                </a>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Date Format</span>
                <span className="data-value">MM/DD/YYYY</span>
              </div>
              <div className="data-col data-col-end">
                <a
                  href="#link"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="link link-primary"
                >
                  Change
                </a>
              </div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Timezone</span>
                <span className="data-value">Bangladesh (GMT +6)</span>
              </div>
              <div className="data-col data-col-end">
                <a
                  href="#link"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                  className="link link-primary"
                >
                  Change
                </a>
              </div>
            </div>
          </div>
        </Block>

        <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <ModalBody>
            <div className="p-2">
              <h5 className="title">Update Profile</h5>
              <ul className="nk-nav nav nav-tabs">
                <li className="nav-item">
                  <a
                    className={`nav-link ${modalTab === "1" && "active"}`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModalTab("1");
                    }}
                    href="#personal"
                  >
                    Personal
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${modalTab === "2" && "active"}`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModalTab("2");
                    }}
                    href="#address"
                  >
                    Address
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                  <Row className="gy-4">
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="full-name">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="full-name"
                          className="form-control"
                          name="first_name"
                          defaultValue={loggedInUser.first_name}
                          onChange={(e) => onInputChange(e)}
                          placeholder="Enter first name"
                          onKeyDown={(e) => checkInputLength(e, 255)}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="last_name">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="last_name"
                          className="form-control"
                          name="last_name"
                          onChange={(e) => onInputChange(e)}
                          defaultValue={loggedInUser.last_name}
                          placeholder="Enter last name"
                          onKeyDown={(e) => checkInputLength(e, 255)}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="phone-no">
                          Phone Number
                        </label>
                        <input
                          type="number"
                          id="phone-no"
                          className="form-control"
                          name="phone_number"
                          onKeyDown={onlyIntegerInputCheck}
                          onClick={(e) => onInputChange(e)}
                          value={loggedInUser.phone_number}
                          placeholder="Phone Number"
                          onChange={(e) => setData({ ...loggedInUser, phone_number: e.target.value })}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="birth-day">
                          Date of Birth
                        </label>
                        <DatePicker
                          selected={new Date(loggedInUser.dob)}
                          className="form-control"
                          onChange={(date) => setData({ ...loggedInUser, dob: getDateStructured(date) })}
                          maxDate={new Date()}
                        />
                      </div>
                    </Col>
                    <Col size="12 d-none">
                      <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="latest-sale" />
                        <label className="custom-control-label" htmlFor="latest-sale">
                          Use full name to display{" "}
                        </label>
                      </div>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            variant="contained"
                            color="primary"
                            size="md"
                            disabled={loading}
                            onClick={(ev) => {
                              ev.preventDefault();
                              submitUserInfoForm();
                            }}
                            startIcon={
                              loading ? <Spinner size="sm" style={{ color: "inherit", borderWidth: "2px" }} /> : ""
                            }
                          >
                            {"Update Profile"}
                          </Button>
                        </li>
                        <li>
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="address">
                  <Row className="gy-4">
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="address-l1">
                          Address Line 1
                        </label>
                        <input
                          type="text"
                          id="address-l1"
                          name="address_1"
                          onChange={(e) => onInputChange(e)}
                          defaultValue={
                            loggedInUser.address_1 != null && loggedInUser.address_1 !== "null"
                              ? loggedInUser.address_1
                              : ""
                          }
                          className="form-control"
                          onKeyDown={(e) => checkInputLength(e, 255)}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="address-l2">
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          id="address-l2"
                          name="address_2"
                          onChange={(e) => onInputChange(e)}
                          defaultValue={
                            loggedInUser.address_2 != null && loggedInUser.address_2 !== "null"
                              ? loggedInUser.address_2
                              : ""
                          }
                          className="form-control"
                          onKeyDown={(e) => checkInputLength(e, 255)}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="address-st">
                          State
                        </label>
                        <input
                          type="text"
                          id="address-st"
                          name="state"
                          onChange={(e) => onInputChange(e)}
                          defaultValue={
                            loggedInUser.state != null && loggedInUser.state !== "null" ? loggedInUser.state : ""
                          }
                          className="form-control"
                          onKeyDown={(e) => checkInputLength(e, 255)}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="address-county">
                          Country
                        </label>
                        <RSelect
                          options={countries}
                          placeholder="US"
                          id="address-county"
                          defaultValue={[
                            {
                              value: loggedInUser?.country_value || 231,
                              label: loggedInUser?.country ? loggedInUser?.country : "US",
                            },
                          ]}
                          onChange={(e) => {
                            setData({ ...loggedInUser, country: e.label, country_value: e.value });
                          }}
                        />
                      </div>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => submitAddressForm()}
                            startIcon={
                              loading ? <Spinner size="sm" style={{ color: "inherit", borderWidth: "2px" }} /> : ""
                            }
                          >
                            Update Address
                          </Button>
                        </li>
                        <li>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default UserProfileRegularPage;
