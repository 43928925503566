import React from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

//^ mui
import { Box, Card, Pagination, Stack, Typography } from "@mui/material";

//^ style variables
import styleVar from "../../assets/scss/_themes-vars.module.scss";

export function DataTable({ columns, data, isCompact = false }) {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  function paginationChangeHandler(_event, page) {
    const actualPage = page - 1;
    table.setPageIndex(actualPage);
  }

  return (
    <>
      <Stack gap={"1rem"}>
        <Box>
          <Stack
            gap={"1rem"}
            justifyContent={"space-between"}
            sx={{
              "@media (min-width: 65.5rem)": {
                flexDirection: "row",
                gap: "0rem",
              },
            }}
          >
            <input
              placeholder="Search for service names..."
              value={table.getColumn("serviceName")?.getFilterValue() ?? ""}
              onChange={(event) => table.getColumn("serviceName")?.setFilterValue(event.target.value)}
              className="form-control"
              style={{ width: "20rem" }}
            />
            <div className="form-control-select">
              <select
                name="DataTables_Table_0_length"
                className="custom-select custom-select-sm form-control form-control-sm"
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                value={table.getState().pagination.pageSize}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            {/* <Stack direction={"row"} alignItems={"center"} gap={"0.625rem"}>
              <Typography>Go to page:</Typography>
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="form-control"
                style={{ width: "5rem", textAlign: "center" }}
              />
            </Stack> */}
          </Stack>
        </Box>
        <Card variant="outlined" sx={{ borderTop: "none", overflow: "auto", borderRadius: "0.425rem" }}>
          <table className={`table table-tranx ${isCompact ? "is-compact" : ""} bg-transparent`}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <>
                    <tr key={headerGroup.id} className="bg-transparent tb-tnx-head">
                      {headerGroup.headers.map((header) => {
                        return (
                          <th key={header.id} className="bg-transparent tb-tnx-id">
                            <Typography variant="subtitle2">
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </Typography>
                          </th>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            </thead>
            <tbody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className="tb-tnx-item bg-transparent"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className="bg-transparent tb-tnx-id">
                          <Typography variant="subtitle2" fontWeight={400}>
                            {cell.getValue()}
                          </Typography>
                        </td>
                      );
                    })}
                  </tr>
                ))
              ) : (
                <tr className="bg-transparent">
                  <td colSpan={columns.length} className="bg-transparent" style={{ height: "6rem" }}>
                    <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
                      <Typography
                        textTransform={"capitalize"}
                        style={{ color: styleVar.errorMain }}
                        fontWeight={"bold"}
                      >
                        No results.
                      </Typography>
                    </Stack>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>
        <Stack direction={"row"} alignItems={"center"} style={{ gap: "1rem" }}></Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={"1rem"}>
            <Pagination
              count={table.getPageCount()}
              onChange={paginationChangeHandler}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
          <Stack direction={"row"} gap={"0.3rem"}>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
            </strong>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
