import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import { Icon } from "../../components/Component";

import { useTheme, useThemeUpdate } from "../provider/Theme";
import { Box, Stack } from "@mui/material";

const AppHeader = ({ fixed, className, app }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const navigate = useNavigate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${process.env.PUBLIC_URL}/user-profile-regular`)}
            className="nk-header-app-name"
          >
            <div className="nk-header-app-logo">
              <Icon name={app.icon} className={app.theme} />
            </div>
            <div className="nk-header-app-info">
              <span className="lead-text">{app.text}</span>
            </div>
          </div>
          <div className="nk-header-tools">
            <Stack direction={"row"} gap={"1.5rem"} className="nk-quick-nav">
              <Box className="notification-dropdown me-n1">
                <Notification />
              </Box>
              <Box className="user-dropdown">
                <User />
              </Box>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppHeader;
