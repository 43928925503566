import { Button, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Icon } from "../Component";

export default function BackButton({ title, onClick, variant, color }) {
  const backTextMotion = {
    hover: {
      x: -3,
    },
  };

  return (
    <motion.div className="d-flex align-items-center justify-content-center" whileHover={"hover"}>
      <Button variant={variant || "outlined"} color={color || "inherit"} onClick={onClick}>
        <Stack direction="row" alignItems={"center"} gap="0.625rem">
          <motion.span variants={backTextMotion} className="d-flex justify-content-center align-items-center">
            <Icon name={"arrow-left"} />
          </motion.span>
          <Typography variant="subtitle2">{title}</Typography>
        </Stack>
      </Button>
    </motion.div>
  );
}
