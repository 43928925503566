import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

//^ layouts
import LayoutNoSidebar from "../layout/Index-nosidebar";
import LayoutApp from "../layout/Index-app";
import UserLayoutApp from "../layout/user/user-layout-app";

//^ pages
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";
import Logout from "../pages/auth/Logout";
import VerifyAccount from "../pages/auth/VerifiedAccount";

//^ user pages
import UserProfileRegular from "../pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/pre-built/user-manage/UserProfileSetting";
import UserProfileActivity from "../pages/pre-built/user-manage/UserProfileActivity";
import UserProfileNotification from "../pages/pre-built/user-manage/UserProfileNotification";
import TwoFactorSettings from "../pages/pre-built/user-manage/TwoFactorSettings/TwoFactorSettings";
import Terms from "../pages/others/Terms";
import Privacy from "../pages/others/Privacy";
import App from "../pages/pre-built/App";

//^ subscription pages
import SubscriptionPackage from "../pages/pre-built/package-subscription/subscription-packages/SubscriptionPackage";
import SubscriptionPackageDetails from "../pages/pre-built/package-subscription/SubscriptionPackageDetails";

//^ invoice pages
import Invoice from "../pages/invoices/Invoice";

//^ service pages
import ServicePage from "../pages/service/ServicePage";
import ServicePlans from "../pages/service/service-plans";
import ServicePlanDetails from "../pages/service/service-plans-details";
import ServiceInvoice from "../pages/service/service-invoice";

const RoutePages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="logout" element={<Logout />} errorElement={<Error404Modern />} />
      <Route path="terms" element={<Terms />} errorElement={<Error404Modern />} />
      <Route path="privacy" element={<Privacy />} errorElement={<Error404Modern />} />

      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<LayoutApp app={{ icon: "user", theme: "bg-success-dim", text: "Account" }} />}
        errorElement={<Error404Modern />}
      >
        <Route path="user-profile-activity" element={<UserProfileActivity />} />
      </Route>

      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<LayoutApp app={{ icon: "user", theme: "bg-success-dim", text: "Account" }} />}
      >
        <Route path="apps" element={<App />} />
      </Route>

      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<UserLayoutApp app={{ icon: "user", theme: "bg-success-dim", text: "Account" }} />}
        errorElement={<Error404Modern />}
      >
        <Route path="user-profile-regular" element={<UserProfileRegular />} />
        <Route path="user-profile-notification" element={<UserProfileNotification />} />
        <Route path="user-profile-setting" element={<UserProfileSetting />} />
        <Route path="two-factor-setting" element={<TwoFactorSettings />} />
        <Route path="subscription-package">
          <Route index={true} element={<SubscriptionPackage />} />
        </Route>
        <Route path="services">
          <Route index={true} element={<ServicePage />} />
          <Route path="service-plan/:subscription/:serviceId">
            <Route index={true} element={<ServicePlans />} />
            <Route path=":planId">
              <Route index={true} element={<ServicePlanDetails />} />
              <Route path="print-invoice/:serviceInvoiceId" element={<ServiceInvoice />} />
            </Route>
          </Route>
        </Route>
        <Route path="subscription-package-details/:plan_id">
          <Route index={true} element={<SubscriptionPackageDetails />} />
          <Route path="print-invoice/:invoiceId" element={<Invoice />} />
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="verify-account/:token/:code" element={<VerifyAccount type="verify" />} exact />
        <Route path="reset-password/:token/:code" element={<VerifyAccount type="reset" />} exact />
        <Route path="reset" element={<ForgotPassword />} />
        <Route path="success" element={<Success />} />

        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />} />
          <Route path="404-classic" element={<Error404Classic />} />
          <Route path="504-modern" element={<Error504Modern />} />
          <Route path="504-classic" element={<Error504Classic />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Route>
    </Routes>
  );
};
export default RoutePages;
