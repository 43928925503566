import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { Tooltip } from "reactstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";

import { useFormik } from "formik";

//^ http request
import { queryClient } from "../../../http";
import { changePasswordHandler, postSet2FAProfileHandler } from "../../../http/post-api";

import Head from "../../../layout/head/Head";
import { Block, BlockBetween, BlockHead, BlockHeadContent } from "../../../components/Component";

//^ mui
import {
  Button,
  Stack,
  Typography,
  Card as MuiCard,
  Box,
  CircularProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";

//^ components
import Title from "../../../components/title/Title";
import PasswordInput from "../../../components/input/password-input";

const UserProfileSettingPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const userProfileData = useSelector((state) => state.userProfile.profileData);

  //^ states
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [formikValEmpty, setFormikValEmpty] = useState(false);

  const [authChecking, setAuthChecking] = useState(true);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const [tooltipTwoFAOpen, setTooltipTwoFAOpen] = useState(false);

  const toggleTooltipTwoFA = () => setTooltipTwoFAOpen(!tooltipTwoFAOpen);

  //^ error handling
  const [setOfTwoFactErrorModel, setSetOfTwoFactErrorModel] = useState(false);

  const {
    isPending: changePasswordIsPending,
    isError: changePasswordIsError,
    error: changePasswordError,
    mutate: changePasswordMutate,
    reset: changePasswordReset,
  } = useMutation({
    mutationKey: ["change-password"],
    mutationFn: changePasswordHandler,
    onSuccess: (data) => {
      if (data.status) {
        toast.success(data.message);
        setFormikValEmpty(true);
        setChangePasswordModal(false);
        handleClose();
      } else {
        toast.error(data.message);
      }
      changePasswordReset();
    },
  });

  //^ Formik Login =======================================================================

  const schema = Yup.object().shape({
    password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
    newPassword: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      const regexStrongPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;

      if (values.password.length < 1) {
        toast.error("Enter current password");
        return;
      }
      if (!values.newPassword.match(regexStrongPassword)) {
        toast.error("Enter strong new password eg. John@1234");
        return;
      }
      if (values.newPassword === values.password) {
        toast.error("Enter new password than current one");
        return;
      }
      if (values.newPassword !== values.confirmPassword) {
        toast.error("Password not matched");
        return;
      }

      const formData = new FormData();
      formData.append("old_password", values.password);
      formData.append("new_password", values.newPassword);
      formData.append("confirm_password", values.confirmPassword);

      changePasswordMutate(formData);
    },
  });

  useEffect(() => {
    if (changePasswordIsError) {
      console.log(changePasswordError);
    }
  }, [changePasswordIsError, changePasswordError]);

  //^ Formik Login =======================================================================

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  //^ set off 2fa mutation query
  const {
    isPending: setOfTwoFactIsPending,
    isError: setOfTwoFactIsError,
    error: setOfTwoFactError,
    mutate: setOfTwoFactMutate,
    reset: setOfTwoFactReset,
  } = useMutation({
    mutationKey: ["set-off-two-fa"],
    mutationFn: postSet2FAProfileHandler,
    onSuccess: (data) => {
      if (data.type === "error") {
        toast.error(data?.message);
      } else if (data.type === "success") {
        toast.success(data?.message);

        Swal.fire({
          title: "OFF 2FA Success",
          text: data.message,
          icon: "success",
        });
        queryClient.invalidateQueries(["get-user-profile-index-app"]);
        queryClient.invalidateQueries(["get-user-profile"]);
      } else if (data.message === "Validation failed") {
        toast.error(data?.message);
      }
      setOfTwoFactReset();
    },
  });

  useEffect(() => {
    if (setOfTwoFactIsError) {
      setSetOfTwoFactErrorModel(setOfTwoFactIsError);
    } else {
      setSetOfTwoFactErrorModel(setOfTwoFactErrorModel);
    }
    // eslint-disable-next-line
  }, [setOfTwoFactError, setOfTwoFactIsError]);

  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
    }

    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  useEffect(() => {
    if (setFormikValEmpty) {
      formik.setValues(initialValues);
      formik.resetForm();
    }

    // eslint-disable-next-line
  }, [formikValEmpty]);

  if (authChecking)
    return (
      <div style={{ height: "80vh" }} className="d-flex w-100 justify-content-center align-items-center">
        <CircularProgress size={30} color="primary" />
      </div>
    );

  const handleOff2fa = () => {
    setOfTwoFactMutate({ twoFactAuth: "0" });
  };

  function handleClose() {
    setFormikValEmpty(true);
    formik.resetForm();
    setChangePasswordModal(false);
  }

  return (
    <React.Fragment>
      <Head title="User Setting" />
      {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)} />}
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent className={"w-100"}>
            <Title title={"Security Settings"} />
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Stack width={"100%"}>
          <MuiCard variant="outlined" sx={{ padding: "1.25rem", borderRadius: "0rem" }}>
            <Stack
              direction={"column"}
              width={"100%"}
              justifyContent={"space-between"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "0rem",
                },
              }}
            >
              <Stack gap={"0rem"}>
                <Typography variant="h6">Change Password</Typography>
                <Typography variant="subtitle1">Set a unique password to protect your account.</Typography>
              </Stack>
              <Box>
                <Button variant="contained" onClick={() => setChangePasswordModal(true)} color="primary">
                  Change Password
                </Button>
              </Box>
            </Stack>
          </MuiCard>
          <MuiCard variant="outlined" sx={{ padding: "1.25rem", borderRadius: "0rem", borderTop: "none" }}>
            <Stack
              direction={"column"}
              width={"100%"}
              justifyContent={"space-between"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "0rem",
                },
              }}
            >
              <Stack gap={"0rem"}>
                <Typography variant="h6">
                  2 Factor Authentication &nbsp;{" "}
                  {userProfileData?.two_fact_auth_status ? <Chip label="Enabled" size="small" color="success" /> : ""}
                </Typography>
                <Typography variant="subtitle1">
                  Activate 2FA for added security. It requires both your password and a special code from a mobile app
                  for login.
                </Typography>
              </Stack>
              <Box>
                {userProfileData?.two_fact_auth_status ? (
                  <>
                    <div id="two-fact-tooltip">
                      <Button
                        variant="contained"
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "Are you sure you want to turn off 2FA security feature? This action cannot be undone.",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, OFF 2FA",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handleOff2fa();
                            }
                          });
                        }}
                        type="click"
                        disabled={setOfTwoFactIsPending}
                        className={"d-flex"}
                        style={{
                          gap: "0.5rem",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: setOfTwoFactIsPending ? "not-allowed" : "",
                          whiteSpace: "nowrap",
                        }}
                        startIcon={setOfTwoFactIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                      >
                        <span>Off 2FA</span>
                      </Button>
                    </div>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipTwoFAOpen}
                      target="two-fact-tooltip"
                      toggle={toggleTooltipTwoFA}
                    >
                      {userProfileData?.two_fact_auth === "1"
                        ? "Email"
                        : userProfileData?.two_fact_auth === "2"
                        ? "Phone Number"
                        : userProfileData?.two_fact_auth === "3"
                        ? "Both"
                        : "Set 2FA"}
                    </Tooltip>
                  </>
                ) : (
                  <Box>
                    <Button variant="contained" color="primary" onClick={() => navigate("/two-factor-setting")}>
                      Set 2FA
                    </Button>
                  </Box>
                )}
              </Box>
            </Stack>
          </MuiCard>
        </Stack>
      </Block>

      <React.Fragment>
        <Dialog
          open={changePasswordModal}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              formik.handleSubmit(event);
            },
            sx: {
              width: "100%",
              minWidth: "unset",
              maxWidth: "unset",
              "@media (min-width: 65.5rem)": {
                width: "600px",
              },
            },
          }}
        >
          <DialogTitle>Change Password</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Stack gap={"1rem"}>
              <Box>
                <PasswordInput
                  placeholder={"Enter your current password"}
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  hasError={formik.errors.password && formik.touched.password}
                  errorMsg={formik.errors.password}
                  required
                />
              </Box>
              <PasswordInput
                placeholder={"Enter new password"}
                name="newPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                hasError={formik.errors.newPassword && formik.touched.newPassword}
                errorMsg={formik.errors.newPassword}
                required
              />
              <PasswordInput
                placeholder={"Enter confirm password"}
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                hasError={formik.errors.confirmPassword && formik.touched.confirmPassword}
                errorMsg={formik.errors.confirmPassword}
                required
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ padding: theme.spacing(2) }}>
            <Button
              startIcon={changePasswordIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
              variant="contained"
              disabled={changePasswordIsPending || !formik.isValid}
              type="submit"
            >
              Change
            </Button>
            <Button variant="contained" type="button" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  );
};

export default UserProfileSettingPage;
