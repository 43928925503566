import { Typography, Stack, Chip } from "@mui/material";
import { useNavigate } from "react-router";
import StartButton from "../../components/button/navigate-button";

function ServiceSubscriptionBtn({ subscription, serviceId }) {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        "@media (min-width: 65.5rem)": {
          justifyContent: "center",
          alignItems: "center",
        },
      }}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
    >
      <StartButton
        size="small"
        variant="outlined"
        color={"primary"}
        sx={{ width: "6rem" }}
        onClick={() => navigate(`service-plan/${subscription ? "renew" : "buy"}/${serviceId}`)}
        title={subscription ? "renew" : "buy"}
      />
    </Stack>
  );
}

export const columns = [
  {
    accessorKey: "srNo",
    header: "Sr No",
    accessorFn: (row) => {
      return (
        <Typography variant="subtitle2" fontWeight={400}>
          {row.srNo}
        </Typography>
      );
    },
  },
  {
    accessorKey: "serviceName",
    header: "Service Name",
  },
  {
    accessorKey: "plan",
    header: () => {
      return (
        <Stack
          sx={{
            "@media (min-width: 65.5rem)": {
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Typography variant="subtitle2">Plan</Typography>
        </Stack>
      );
    },
    accessorFn: (row) => {
      return (
        <Stack
          sx={{
            "@media (min-width: 65.5rem)": {
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {row.plan.name ? (
            <Typography variant="subtitle2" fontWeight={400}>
              {row.plan.name}
            </Typography>
          ) : (
            <Typography variant="subtitle2" fontWeight={400}>
              ---
            </Typography>
          )}
        </Stack>
      );
    },
  },
  {
    accessorKey: "package",
    header: () => (
      <Stack
        sx={{
          "@media (min-width: 65.5rem)": {
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Typography variant="subtitle2">{"Package Name"}</Typography>
      </Stack>
    ),
    accessorFn: (row) => {
      return (
        <Stack
          sx={{
            "@media (min-width: 65.5rem)": {
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {row.package.name ? (
            <Typography variant="subtitle2" fontWeight={400}>
              {row.package.name}
            </Typography>
          ) : (
            <Typography variant="subtitle2" fontWeight={400}>
              ---
            </Typography>
          )}
        </Stack>
      );
    },
  },
  {
    accessorKey: "status",
    header: () => (
      <Stack
        sx={{
          "@media (min-width: 65.5rem)": {
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Typography variant="subtitle2">{"Status"}</Typography>
      </Stack>
    ),
    accessorFn: (row) => {
      return (
        <Stack
          sx={{
            "@media (min-width: 65.5rem)": {
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {row.status ? (
            <Chip
              color={row.status === "active" ? "success" : "error"}
              size="small"
              sx={{ textTransform: "capitalize" }}
              label={row.status}
            />
          ) : (
            <Typography variant="subtitle2" fontWeight={400}>
              ---
            </Typography>
          )}
        </Stack>
      );
    },
  },

  {
    accessorKey: "endDate",
    header: () => (
      <Stack
        sx={{
          "@media (min-width: 65.5rem)": {
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {"End Date"}
      </Stack>
    ),
    enableColumnFilter: true,
    accessorFn: (row) => {
      return (
        <Stack
          sx={{
            "@media (min-width: 65.5rem)": {
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {row.endDate ? (
            <Typography variant="subtitle2" fontWeight={400}>
              {new Date(row.endDate).toLocaleDateString()}
            </Typography>
          ) : (
            <Typography variant="subtitle2" fontWeight={400}>
              {"---"}
            </Typography>
          )}
        </Stack>
      );
    },
  },
  {
    accessorKey: "subscription",
    header: () => (
      <Stack
        sx={{
          "@media (min-width: 65.5rem)": {
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Typography variant="subtitle2">Subscription</Typography>
      </Stack>
    ),
    accessorFn: (row) => {
      return <ServiceSubscriptionBtn subscription={row?.subscription} serviceId={row.serviceId} />;
    },
  },
];
