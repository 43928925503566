import { getCookie } from "../../utils/Utils";

export async function getAllCountries({ signal }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`, {
    signal,
  });

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occurred while fetching countries");
    error.code = response.status;
    error.info = errorInfo;

    throw error;
  }

  const resData = await response.json();
  return resData;
}

export async function getIpv4IpAddress({ signal }) {
  const response = await fetch(`https://api.ipify.org?format=json`, {
    signal,
  });

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occurred while get a IPv4 address.");
    error.code = response.status;
    error.info = errorInfo;

    throw error;
  }

  const resData = await response.json();
  return resData;
}

export async function getUserProfileHandler({ signal }) {
  let route = "account/get-profile";

  let formData = new FormData();

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/${route}`, {
    method: "POST",
    body: formData,
    headers: {
      authToken: getCookie("authToken", null),
    },
    signal,
  });

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occurred while getting a profile of the user.");
    error.code = response.status;
    error.info = errorInfo;

    throw error;
  }

  const resData = await response.json();
  return resData;
}

//^ Subscription http requests =================================================================

export async function getPackagesSubscriptionHandler({ signal }) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-package-list`, {
      cache: "no-store",
      headers: {
        authToken: getCookie("authToken", null),
      },
      signal,
    });

    if (!response.ok) {
      const errorInfo = await response.json();

      const error = new Error("An Error occurred while getting a subscription packages lists.");
      error.code = response.status;
      error.info = errorInfo;

      throw error;
    }

    const resData = await response.json();
    return resData;
  } catch (error) {
    throw error;
  }
}

//^ Subscription http requests =================================================================

//^ APPS http requests =================================================================

export async function getAppContent({ signal, app_category = 1 }) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/apps?app_category=${app_category}`, {
      headers: {
        authToken: getCookie("authToken", null),
      },
      signal,
    });

    if (!response.ok) {
      const errorInfo = await response.json();
      const error = new Error("An Error occurred while getting app content.");
      error.code = response.status;
      error.info = errorInfo;
      throw error;
    }
    const resData = await response.json();
    return resData;
  } catch (error) {
    throw error;
  }
}

//^ APPS http requests =================================================================

//^ Services http requests =================================================================

export async function getServices({ limit = 100 }) {
  const baseUrl = new URL(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-services`);

  const queryParams = {
    limit: limit,
  };

  for (const [key, value] of Object.entries(queryParams)) {
    baseUrl.searchParams.append(key, value);
  }

  const response = await fetch(baseUrl, { headers: { authToken: getCookie("authToken", undefined) } });

  if (!response.ok) {
    const errorInfo = await response.json();
    const error = new Error("An Error occurred while getting services.");
    error.code = response.status;
    error.info = errorInfo;
    throw error;
  }

  const resData = await response.json();
  return resData;
}

//^ Services http requests =================================================================
