import { useNavigate, useParams } from "react-router";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import styleVar from "../../../assets/scss/_themes-vars.module.scss";

//^ http request
import { getServicePlan } from "../../../http/post-api";

//^ component
import Title from "../../../components/title/Title";
import ErrorAlert from "../../../components/ui/modals/error-model/ErrorAlert";
import PackageSubscriptionDetailSkeleton from "../../../components/ui/loader/Skeleton/packageSubscriptionDetailSkeleton";
import { getCookie } from "../../../utils/Utils";
import { GreetingMessage } from "../../pre-built/package-subscription/current-subscription/CurrentSubscription";
import { ServiceList } from "../service-plans";
import BackButton from "../../../components/button/back-button";
import Switch from "../../../components/ui/switch/Switch";
import StartButton from "../../../components/button/navigate-button";
import Head from "../../../layout/head/Head";
import ServicePayment from "./service-payment";

export default function ServicePlansDetails() {
  const { planId, serviceId } = useParams();

  const [features, setFeatures] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [onlyPrice, setOnlyPrice] = useState(0);
  const [onlyTotalPrice, setOnlyTotalPrice] = useState(0);
  const [onlyAugerFee, setOnlyAugerFee] = useState(0);
  const [augerFee, setAugerFee] = useState(0);
  const [showSBCToken, setShowSBCToken] = useState(false);
  const [plan, setPlan] = useState({});
  const [showPayment, setShowPayment] = useState(false);

  const servicePackageValidity = useSelector((state) => state.servicePlan.servicePlanValidity);
  const navigate = useNavigate();

  function handleClickShowSBCToken() {
    setShowSBCToken((prevSBCToken) => !prevSBCToken);
  }

  const {
    data: servicePlanData,
    isLoading: servicePlanIsLoading,
    isRefetching: servicePlanIsRefetching,
    isError: servicePlanIsError,
    error: servicePlanError,
    refetch: servicePlanRefetch,
  } = useQuery({
    queryKey: ["get-service-plan"],
    queryFn: async () => getServicePlan({ planId: parseInt(planId), serviceId: parseInt(serviceId) }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!servicePlanIsLoading || !servicePlanIsRefetching) {
      if (!servicePlanData?.status) {
        if (servicePlanData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        const featureObj = JSON.parse(servicePlanData?.data?.plan?.features);
        const features = Object.keys(featureObj).map((title) => ({
          title,
          ...featureObj[title],
        }));
        const planPrice = servicePackageValidity
          ? servicePlanData?.data?.plan?.yearly_price?.price
          : servicePlanData?.data?.plan?.monthly_price?.price;
        const augerFee = servicePackageValidity
          ? servicePlanData?.data?.plan?.yearly_price?.auger_price
          : servicePlanData?.data?.plan?.monthly_price?.auger_price;

        setFeatures(features);
        setPlan(servicePlanData?.data?.plan);

        const price = showSBCToken ? (planPrice / servicePlanData?.data?.token_value)?.toFixed(6) : planPrice;
        const augerPrice = showSBCToken
          ? ((planPrice * augerFee) / 100 / servicePlanData?.data?.token_value)?.toFixed(6)
          : augerFee;

        setPrice(price);
        setOnlyPrice(planPrice);
        setOnlyTotalPrice(planPrice + augerFee);
        setOnlyAugerFee(augerFee);
        setAugerFee(augerPrice);
        setTotalPrice(
          showSBCToken
            ? (parseFloat(price) + parseFloat(augerPrice)).toFixed(6)
            : (parseFloat(price) + parseFloat(augerPrice)).toFixed(2)
        );
      }
    }
  }, [servicePlanData, servicePlanIsLoading, servicePlanIsRefetching, servicePackageValidity, showSBCToken]);

  useEffect(() => {
    if (servicePlanIsError) {
      console.log(servicePlanError?.info);
    }
  }, [servicePlanError, servicePlanIsError]);

  const handlePaymentModelClose = useCallback((isModelOpen) => {
    setShowPayment(isModelOpen);
  }, []);

  return (
    <>
      <Head title={"Service Subscription"} />

      {servicePlanIsError && (
        <ErrorAlert
          title={`${servicePlanError?.code || 500}`}
          description={`${servicePlanError?.info?.message || "Something went wrong"}`}
          isConformed={() => servicePlanRefetch()}
        />
      )}

      {servicePlanIsLoading || servicePlanIsRefetching ? (
        <PackageSubscriptionDetailSkeleton />
      ) : (
        <>
          {showPayment && (
            <ServicePayment
              payment={parseFloat(onlyPrice) || 0.0}
              accountToken={servicePlanData?.data?.account_tokens || 0.0}
              isPaymentOpen={showPayment}
              onPaymentModelClose={handlePaymentModelClose}
              totalPrice={parseFloat(onlyTotalPrice)}
              tokenValue={servicePlanData?.data?.token_value || 0.0}
              augerPrice={parseFloat(onlyAugerFee)}
              serviceName={servicePlanData?.data?.plan?.name}
            />
          )}
          <Stack gap={"4.25rem"}>
            <Stack gap={"1.25rem"}>
              <Title
                title={`${plan.service_name} Subscription`}
                endComponent={
                  <Box>
                    <BackButton title={"Plans"} onClick={() => navigate(-1)} />
                  </Box>
                }
              />
              <GreetingMessage username={getCookie("username", undefined)} />
            </Stack>
            <Stack
              justifyContent={"space-between"}
              gap={"1.25rem"}
              paddingBottom={"3rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box width={"100%"}>
                <Stack gap={"1.25rem"}>
                  <Typography
                    variant="h5"
                    fontWeight={"600"}
                  >{`${servicePlanData?.data?.plan?.name} Plan Includes`}</Typography>
                  <Stack gap={"0.325rem"}>
                    {features.length > 0
                      ? features.map((service, index) => {
                          return (
                            <React.Fragment key={index}>
                              <ServiceList keyName={service.title} value={service.text} />
                            </React.Fragment>
                          );
                        })
                      : null}
                  </Stack>
                </Stack>
              </Box>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ borderColor: styleVar.primaryMain, borderWidth: "1.5px", borderRadius: "100%" }}
              />
              <Box width={"100%"}>
                <Card elevation={4} sx={{ borderRadius: "0.625rem", padding: "1.25rem" }}>
                  <Stack gap={"1.25rem"}>
                    <Typography textAlign={"center"} variant="h6" fontWeight={600}>
                      {"Proceed to Payment"}
                    </Typography>
                    <Stack justifyContent={"flex-end"} direction={"row"}>
                      <Switch
                        size={"sm"}
                        checkedLabel={"USD"}
                        unCheckedLabel={"SBC"}
                        checked={showSBCToken}
                        onClick={handleClickShowSBCToken}
                      />
                    </Stack>
                    <Stack padding={"0rem 1.25rem"} gap={"1.25rem"}>
                      <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                        <Typography variant="subtitle1">{"Price"}</Typography>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {`${showSBCToken ? "SBC " : "$"}${price}`}
                        </Typography>
                      </Stack>
                      <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                        <Typography variant="subtitle1">{"Auger Fee"}</Typography>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {`${showSBCToken ? "SBC " : "$"}${augerFee}`}
                        </Typography>
                      </Stack>
                      <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                        <Typography variant="subtitle1">{"Subscription Validity"}</Typography>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {`${servicePackageValidity ? "1 Year" : "1 Month"}`}
                        </Typography>
                      </Stack>
                      <Divider sx={{ marginTop: "1rem" }} />
                      <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {"Total Price"}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {`${showSBCToken ? "SBC " : "$"}${totalPrice}`}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack alignItems={"center"} justifyContent={"center"} direction={"row"}>
                      <StartButton
                        title={`${
                          servicePlanData?.data?.is_subscribed
                            ? servicePlanData?.data?.subscribed_price >= onlyPrice
                              ? "Renew Now"
                              : "Upgrade Now"
                            : "Buy Now"
                        }`}
                        onClick={() => setShowPayment(true)}
                      />
                    </Stack>
                  </Stack>
                </Card>
              </Box>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}
