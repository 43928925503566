import { Stack, Box, Skeleton, Card } from "@mui/material";
import { Container } from "reactstrap";

export default function SubscriptionInvoicesSkeleton() {
  return (
    <Box width={"100%"} height={"100vh"} padding={"1.25rem"}>
      {" "}
      <Stack gap={"4rem"}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack>
            <Skeleton animation="wave" variant="rounded" width={"15rem"} sx={{ height: "2.6rem" }} />
            <Skeleton animation="wave" variant="text" width={"75%"} />
          </Stack>
          <Stack direction={"row"} gap={"1.25rem"}>
            <Skeleton animation="wave" variant="rounded" width={"5rem"} sx={{ height: "2.6rem" }} />
            <Skeleton animation="wave" variant="rounded" width={"5rem"} sx={{ height: "2.6rem" }} />
          </Stack>
        </Stack>
        <Container>
          <Card variant="outlined w-100" sx={{ padding: "1.25rem" }}>
            <Box className="w-100">
              <Stack>
                <Stack alignItems={"center"} justifyContent={"center"} direction={"row"} width={"100%"}>
                  <Skeleton animation="wave" variant="rounded" sx={{ width: "15rem", height: "8rem" }} />
                </Stack>
                <div className="invoice-head">
                  <div className="invoice-contact">
                    <span className="overline-title">
                      <Skeleton animation="wave" variant="text" width={"6rem"} />
                    </span>
                    <div className="invoice-contact-info">
                      <Stack gap={"1rem"}>
                        <Skeleton animation="wave" variant="rounded" width={"15rem"} sx={{ height: "2rem" }} />

                        <ul className="list-plain">
                          <li>
                            <Stack direction={"row"} gap={"0.625rem"}>
                              <Skeleton animation="wave" variant="rectangular" width={"1.5rem"} height={"1rem"} />
                              <Skeleton animation="wave" variant="text" width={"10rem"} />
                            </Stack>
                          </li>
                        </ul>
                      </Stack>
                    </div>
                  </div>
                  <div className="invoice-desc">
                    <h3 className="title">
                      <Skeleton animation="wave" variant="text" width={"6rem"} height={"3rem"} />
                    </h3>
                    <ul className="list-plain">
                      <li className="invoice-id">
                        <Skeleton animation="wave" variant="text" width={"6rem"} />
                      </li>
                      <li className="invoice-date">
                        <Skeleton animation="wave" variant="text" width={"6rem"} />
                      </li>
                      <li className="invoice-date">
                        <Skeleton animation="wave" variant="text" width={"6rem"} />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-bills">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-150px">
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </th>
                          <th className="w-60">
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </th>
                          <th>
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </th>
                          <th>
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </th>
                          <th>
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.from({ length: 5 }).map((_, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Skeleton animation="wave" variant="text" width={"6rem"} />
                              </td>
                              <td>
                                <Skeleton animation="wave" variant="text" width={"6rem"} />
                              </td>
                              <td>
                                <Skeleton animation="wave" variant="text" width={"6rem"} />
                              </td>
                              <td>
                                <Skeleton animation="wave" variant="text" width={"6rem"} />
                              </td>
                              <td>
                                <Skeleton animation="wave" variant="text" width={"6rem"} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                          <td>
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                          <td>
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                          <td>
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                          <td>
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                          <td>
                            <Skeleton animation="wave" variant="text" width={"6rem"} />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className="nk-notes ff-italic fs-12px text-soft">
                      <Skeleton animation="wave" variant="text" width={"50%"} />
                    </div>
                  </div>
                </div>
              </Stack>
            </Box>
          </Card>
        </Container>
      </Stack>
    </Box>
  );
}
