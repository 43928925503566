import { useState } from "react";

//^ utils
import { Stack, Typography } from "@mui/material";
import { IOSSwitch } from "../../input/switch/MuiSwitch";

export default function Switch({ checkedLabel, unCheckedLabel, checked, onClick, size }) {
  const [isChecked, setIsChecked] = useState(checked || false);

  function toggleCheckHandler() {
    setIsChecked((prevChecked) => !prevChecked);
    onClick(!isChecked);
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{checkedLabel}</Typography>
        <IOSSwitch checked={isChecked} onClick={toggleCheckHandler} />
        <Typography>{unCheckedLabel}</Typography>
      </Stack>
      {/* <div
        className={`${classes["btn-frame"]} ${isChecked ? classes["checked"] : ""} ${isDark ? classes["dark"] : ""} ${
          classes[`${size}`]
        }`}
      >
        <input
          id="toggle-on"
          className={`${classes["toggle"]} ${classes["toggle-left"]}`}
          name="toggle"
          value="false"
          type="radio"
          checked={isChecked}
          onChange={toggleCheckHandler}
        />
        <label htmlFor="toggle-on" className={`${classes["btn"]} ${classes["left"]}`}>
          {checkedLabel}
        </label>
        <input
          id="toggle-off"
          className={`${classes["toggle"]} ${classes["toggle-right"]}`}
          name="toggle"
          value="true"
          type="radio"
          checked={!isChecked}
          onChange={toggleCheckHandler}
        />
        <label htmlFor="toggle-off" className={`${classes["btn"]} ${classes["right"]}`}>
          {unCheckedLabel}
        </label>
      </div> */}
    </>
  );
}
