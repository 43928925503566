import React from "react";

import { Skeleton, Card, Stack, Box } from "@mui/material";

export default function DataTableSkeletonLoader() {
  return (
    <>
      <Stack gap={"1rem"} width={"100%"}>
        <Box width={"100%"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Skeleton animation={"wave"} variant="rounded" width={"15rem"} height={"2.5rem"} />
            <Stack direction={"row"} alignItems={"center"} gap={"0.625rem"}>
              <Skeleton animation={"wave"} variant="text" width={"5rem"} />
              <Skeleton animation={"wave"} variant="rounded" width={"5rem"} height={"2rem"} />
            </Stack>
          </Stack>
        </Box>
        <Card variant="outlined" sx={{ borderTop: "none", overflow: "auto", borderRadius: "0.425rem" }}>
          <table className={`table table-tranx is-compact bg-transparent`}>
            <thead>
              {Array.from({ length: 1 }).map((_, index) => {
                return (
                  <>
                    <tr key={index} className="bg-transparent tb-tnx-head">
                      {Array.from({ length: 5 }).map((_, index) => {
                        return (
                          <th key={index} className="bg-transparent tb-tnx-id">
                            <Skeleton sx={{ height: "2.5rem" }} animation="wave" variant="text" width={"100%"} />
                          </th>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            </thead>
            <tbody>
              {Array.from({ length: 10 }).map((_, index) => (
                <tr key={index} data-state={"selected"} className="tb-tnx-item bg-transparent">
                  {Array.from({ length: 5 }).map((_, index) => {
                    return (
                      <td key={index} className="bg-transparent tb-tnx-id">
                        <Skeleton sx={{ height: "1.5rem" }} animation="wave" variant="text" width={"100%"} />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <Stack direction={"row"} alignItems={"center"} style={{ gap: "1rem" }}></Stack>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack direction={"row"} gap={"1rem"}>
            <Skeleton animation={"wave"} variant="text" width={"15rem"} />
            <Skeleton animation={"wave"} variant="rounded" width={"5rem"} height={"2.5rem"} />
          </Stack>
          <Stack direction={"row"} gap={"0.3rem"}>
            <Skeleton animation={"wave"} variant="rounded" width={"7rem"} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
