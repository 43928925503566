import { useState } from "react";

// styles
import styles from "../../assets/scss/components/Input/PasswordInput.module.scss";

// icons
import OpenEye from "@mui/icons-material/RemoveRedEye";
import CloseEye from "@mui/icons-material/VisibilityOff";
import { IconButton, Stack, Typography } from "@mui/material";

const PasswordInput = ({
  ref,
  className,
  onChange,
  onClick,
  onBlur,
  name,
  id,
  placeholder,
  required,
  value,
  autoComplete,
  hasError,
  errorMsg,
}) => {
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const passwordHandler = () => {
    setIsEyeOpen(!isEyeOpen);
  };
  return (
    <section className={styles["input-model"]}>
      <Stack gap={"0.325rem"} className={styles["input"]}>
        <input
          ref={ref}
          type={isEyeOpen ? "text" : "password"}
          className={`${styles["signup-input"]} form-control form-control-lg bg-transparent ${className}`}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          name={name}
          id={id}
          placeholder={placeholder}
          required={required}
          value={value}
          autoComplete={autoComplete}
        />
        {hasError ? (
          <Typography fontWeight={600} className="invalid">
            {errorMsg}
          </Typography>
        ) : (
          ""
        )}
        <IconButton className={`position-absolute`} style={{ right: "2rem", transform: "translateY(0.3rem)" }} onClick={passwordHandler}>
          {isEyeOpen ? <OpenEye sx={{ fontSize: "1.25rem" }} /> : <CloseEye sx={{ fontSize: "1.25rem" }} />}
        </IconButton>
      </Stack>
    </section>
  );
};

export default PasswordInput;
