import { useEffect } from "react";
import Swal from "sweetalert2";

const ErrorAlert = ({ title, description, status, clg, isConformed }) => {
  useEffect(() => {
    Swal.fire({
      icon: "error",
      title: title,
      text: description,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        isConformed && isConformed();
      }
    });

    if (clg) {
      console.log(clg);
    }
    //eslint-disable-next-line
  }, [title, description, status]);

  return null;
};

export default ErrorAlert;
