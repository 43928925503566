import { Modal, ModalBody } from "reactstrap";
import { Button, Icon } from "../../../Component";

export default function ErrorModel({ isOpen, toggleOpen, title, description, onConfirmed }) {
  return (
    <Modal isOpen={isOpen} toggle={toggleOpen}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></Icon>
          <h4 className="nk-modal-title">{title}</h4>
          <div className="nk-modal-text">
            <p className="lead">{description}</p>
          </div>
          <div className="nk-modal-action mt-3">
            <Button color="light" size="lg" className="btn-mw" onClick={onConfirmed}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
