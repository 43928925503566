import { Button, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { Icon } from "../Component";

export default function StartButton({ title, onClick, color, variant, ...props }) {
  const nextTextMotion = {
    hover: {
      x: 3,
    },
  };

  return (
    <motion.div className="d-flex align-items-center justify-content-center" whileHover={"hover"}>
      <Button variant={variant || "outlined"} color={color || "inherit"} onClick={onClick} {...props}>
        <Stack direction="row" alignItems={"center"} gap="0.625rem">
          {title}
          <motion.span variants={nextTextMotion} className="d-flex justify-content-center align-items-center">
            <Icon name={"arrow-right"} />
          </motion.span>
        </Stack>
      </Button>
    </motion.div>
  );
}
