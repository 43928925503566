import { Box } from "@mui/material";
import ServiceTable from "../../tables/service/table";
import Title from "../../components/title/Title";
import Head from "../../layout/head/Head";

export default function ServicePage() {
  return (
    <Box>
      <Head title="Services" />
      <Title title={"Services"} />
      <ServiceTable />
    </Box>
  );
}
