import React, { useState, useEffect, useContext } from "react";

import Head from "../../../layout/head/Head";

import { BlockBetween, BlockContent, BlockHead, BlockHeadContent, MuiSwitch } from "../../../components/Component";
import { setNotification } from "../../../controller/profile_controller";
import { UserContext } from "./UserContext";
import Title from "../../../components/title/Title";
import { Typography } from "@mui/material";

const UserProfileNotificationPage = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  //^ context states
  const { loggedInUser, setData } = useContext(UserContext);

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="User Notification"></Head>
      {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent className={"w-100"}>
            <Title title={"Notification Settings"} />
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <Typography variant="h6">Security Alerts</Typography>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockContent>
        <div className="gy-3">
          <div className="g-item">
            <MuiSwitch
              id="custom-switch"
              name="unusual_activity"
              onClick={(e) => setNotification(e, "unusual_activity", loggedInUser, setData)}
            >
              Notify Email Updates
            </MuiSwitch>
          </div>
          <div className="g-item d-none">
            <div className="custom-control custom-switch">
              <MuiSwitch
                id="custom-switch2"
                name="browser_login"
                onClick={(e) => setNotification(e, "browser_login", loggedInUser, setData)}
              >
                Notify New Browser Sign-ins
              </MuiSwitch>
            </div>
          </div>
        </div>
      </BlockContent>

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <Typography variant="h6">News</Typography>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockContent>
        <div className="gy-3">
          <div className="g-item">
            <MuiSwitch
              id="custom-switch3"
              name="sales"
              onClick={(e) => setNotification(e, "sales", loggedInUser, setData)}
            >
              Email Sales Updates
            </MuiSwitch>
          </div>
          <div className="g-item">
            <MuiSwitch
              id="feature-update"
              name="features"
              onClick={(e) => setNotification(e, "features", loggedInUser, setData)}
            >
              Feature Updates via Email
            </MuiSwitch>
          </div>
          <div className="g-item">
            <MuiSwitch onClick={(e) => setNotification(e, "tips", loggedInUser, setData)}>Account Tips Email</MuiSwitch>
          </div>
        </div>
      </BlockContent>
    </React.Fragment>
  );
};

export default UserProfileNotificationPage;
