import { createSlice } from "@reduxjs/toolkit";

import PlanS1 from "../../images/icons/plan-s1.svg";
import PlanS2 from "../../images/icons/plan-s2.svg";
import PlanS3 from "../../images/icons/plan-s3.svg";
const initialState = {
  validity: false,
  purchase: true,
  auger_fee: 2.9,
  token_value: null,
  account_tokens: 0,
  planList: [
    {
      id: 1,
      name: "Starter",
      logo: PlanS1,
      desc: "If you are a small business amn please select this plan",
      amount: 99,
      monthly_price: 10,
      yearly_price: 120,
      services: [],
      userNumber: 1,
      tags: false,
    },
    {
      id: 2,
      name: "Pro",
      logo: PlanS2,
      desc: "If you are a small business amn please select this plan",
      amount: 299,
      monthly_price: 10,
      yearly_price: 120,
      services: [],
      userNumber: 5,
      tags: true,
    },
    {
      id: 3,
      name: "Enterprise",
      logo: PlanS3,
      desc: "If you are a small business amn please select this plan",
      amount: 599,
      monthly_price: 10,
      yearly_price: 120,
      services: [],
      userNumber: 20,
      tags: false,
    },
  ],
  plan: null,
};

const packageSubscriptionSlice = createSlice({
  initialState,
  name: "package-subscriptions",
  reducers: {
    setPlanList: (state, action) => {
      state.planList = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setValidity: (state, action) => {
      state.validity = action.payload;
    },
    setPurchase: (state, action) => {
      state.purchase = action.payload;
    },
    setTokenValue: (state, action) => {
      state.token_value = action.payload;
    },
    setAugerFee: (state, action) => {
      state.auger_fee = action.payload;
    },
    setAccountTokens: (state, action) => {
      state.account_tokens = action.payload;
    },
  },
});

export const { setPlanList, setPlan, setValidity, setPurchase, setTokenValue, setAugerFee, setAccountTokens } =
  packageSubscriptionSlice.actions;

export default packageSubscriptionSlice.reducer;
