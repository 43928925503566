import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Avatar, Box } from "@mui/material";

//^ http request
import { getProfileImageHandler } from "../../../http/post-api";

//^ portals
import ImageModal from "../../../components/ui/modals/image-modal";
import { AnimatePresence } from "framer-motion";

const AccountAvatar = ({ profileName, showImg = false }) => {
  const [imageUrl, setFileURL] = useState(null);
  const [showImgModal, setShowImgModal] = useState(false);

  const {
    data: getProfileImgData,
    isLoading: getProfileImgIsLoading,
    isRefetching: getProfileImgIsRefetching,
    // isError: getProfileImgIsError,
    // error: getProfileImgError,
  } = useQuery({
    queryKey: ["get-profile-image"],
    queryFn: getProfileImageHandler,
    gcTime: 0,
    staleTime: Infinity,
  });

  // useEffect(() => {
  //   if (getProfileImgIsError) {
  //     console.log(getProfileImgError?.info);
  //   }
  // }, [getProfileImgIsError, getProfileImgError]);

  useEffect(() => {
    if (!getProfileImgIsLoading || !getProfileImgIsRefetching) {
      if (getProfileImgData) {
        const imageUrl = URL.createObjectURL(getProfileImgData);
        if (imageUrl) {
          setFileURL(imageUrl);
        } else {
          setFileURL(null);
        }
      }
    }
  }, [getProfileImgIsLoading, getProfileImgIsRefetching, getProfileImgData]);

  function toggleImageModalHandler() {
    setShowImgModal((prevShowImgModal) => !prevShowImgModal);
  }

  return (
    <>
      <AnimatePresence>
        {showImgModal && <ImageModal image={imageUrl} onBackdrop={toggleImageModalHandler} />}
      </AnimatePresence>
      {showImg && getProfileImgData ? (
        getProfileImgIsLoading && !getProfileImgData ? (
          <>
            <Avatar alt={profileName} src={imageUrl} />
          </>
        ) : (
          <Box onClick={toggleImageModalHandler} sx={{ cursor: "pointer" }}>
            <Avatar alt={profileName} src={imageUrl} />
          </Box>
        )
      ) : (
        <Avatar alt={profileName} src={imageUrl} />
      )}
    </>
  );
};

export default AccountAvatar;
