import React, { useCallback, useState } from "react";

//^ ui
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

//^ component
import ImageCropper from "../image-cropper/ImageCropper";

const ProfilePicture = (props) => {
  const [dialogOpen, setDialogOpen] = useState(props.isOpen || false);

  // eslint-disable-next-line
  const [imageUrl, setImageUrl] = useState(props.imageUrl || "");
  const [originalImageUrl, setOriginalImageUrl] = useState("");
  // eslint-disable-next-line
  const [originalImgFile, setOriginalImgFile] = useState("");

  const handleUpdateAvatar = useCallback((imageUrl, originalImgUrl) => {
    if (imageUrl.trim().length > 0) {
      setImageUrl(imageUrl);
      setOriginalImageUrl(originalImgUrl);
      props.updateProfileImage?.(imageUrl);
    }

    // eslint-disable-next-line
  }, []);

  // const handlePreviewImage = () => {};

  function handleClose() {
    setDialogOpen(false);
    props.onModalClose(false);
  }

  const handleGetOriginal = useCallback((originalImg) => {
    setOriginalImgFile(originalImg);
  }, []);

  const handleModelClose = useCallback((isOpen) => {
    setDialogOpen(isOpen);
    props.onModalClose(isOpen);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          sx: {
            width: "100%",
            "@media (min-width: 65.5rem)": {
              height: "100%",
            },
            minWidth: "auto",
            maxWidth: "unset",
          },
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Change Photo</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <ImageCropper
          handleModalClose={handleModelClose}
          originalImgSrc={originalImageUrl}
          updateAvatar={handleUpdateAvatar}
          getOriginalImgFile={handleGetOriginal}
          onModalClose={handleModelClose}
        />
      </Dialog>
    </>
  );
};

export default ProfilePicture;
