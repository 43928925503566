import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

//^ redux actions
import { userProfileAction } from "../store/slices/user-profile-slice";

//^ http request
import { getUserProfileHandler } from "../http/get-api";

//^ utils
import { toggleErrorModel } from "../utils/Utils";

import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppHeader from "./header/AppHeader";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";

//^ redux slice
import { muiAction } from "../store/slices/mui-slice";

import { useTheme } from "./provider/Theme";
import { UserContext } from "../pages/pre-built/user-manage/UserContext";
import Profile from "./Profile";

//^ components
import ErrorModel from "../components/ui/modals/error-model/ErrorModel";

const Layout = ({ title, app }) => {
  const muiTheme = useSelector((state) => state.mui.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const loggedInUser = useContext(UserContext);

  const [data, setData] = useState(loggedInUser);

  //& Error States
  const [userProfileErrorModel, setUserProfileErrorModel] = useState(false);

  const {
    data: userProfileData,
    isLoading: userProfileIsLoading,
    isError: userProfileIsError,
    error: userProfileError,
    refetch: userProfileRefetch,
  } = useQuery({
    queryKey: ["get-user-profile-index-app"],
    queryFn: ({ signal }) => getUserProfileHandler({ signal }),
  });

  useEffect(() => {
    if (!userProfileIsLoading) {
      if (userProfileData?.status) {
        dispatch(userProfileAction.setProfileData({ profileData: userProfileData?.data?.profile }));
      } else {
        if (userProfileData?.redirect) {
          navigate("/login");
        }
      }
    }
    // eslint-disable-next-line
  }, [userProfileIsLoading, userProfileData]);

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#666" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  useEffect(() => {
    if (userProfileIsError) {
      setUserProfileErrorModel(userProfileIsError);
    } else {
      setUserProfileErrorModel(userProfileIsError);
    }
  }, [userProfileIsError, userProfileError]);

  useEffect(() => {
    const cookieValue = Cookies.get("authToken");
    if (!cookieValue) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ErrorModel
        isOpen={userProfileErrorModel}
        toggleOpen={() => toggleErrorModel(setUserProfileErrorModel, userProfileErrorModel)}
        title={"Something went wrong"}
        description={"Unable to verify otp!"}
        onConfirmed={() => {
          toggleErrorModel(setUserProfileErrorModel, userProfileErrorModel);
          userProfileRefetch();
        }}
      />
      <UserContext.Provider value={{ loggedInUser: data, setData }}>
        <Profile />
        <Head title={!title && "Loading"} />
        <AppRoot className="npc-apps apps-only">
          <AppMain>
            {theme.sidebarMobile && <Sidebar fixed />}
            <AppWrap>
              <AppHeader app={app} fixed />
              {userProfileIsLoading ? (
                <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                  {/* <Spinner /> */}
                </div>
              ) : (
                <Outlet />
              )}
            </AppWrap>
          </AppMain>
        </AppRoot>
      </UserContext.Provider>
    </>
  );
};
export default Layout;
