import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import SimpleBar from "simplebar-react";

//^ stylesheet
import classes from "../layout.module.scss";

import Sidebar from "../sidebar/Sidebar";
import Head from "../head/Head";
import AppHeader from "../header/AppHeader";
import AppRoot from "../global/AppRoot";
import AppMain from "../global/AppMain";
import AppWrap from "../global/AppWrap";
import { useTheme } from "../provider/Theme";
import { useTheme as useMuiTheme } from "@mui/material/styles";

//^ redux actions
import { userProfileAction } from "../../store/slices/user-profile-slice";
import { muiAction } from "../../store/slices/mui-slice";

//^ http request
import { getUserProfileHandler } from "../../http/get-api";

//^ utils
import { toggleErrorModel } from "../../utils/Utils";

//^ ui
import { BlockBetween, BlockHead, BlockHeadContent } from "../../components/Component";
import { Box, Paper } from "@mui/material";

import { UserContext } from "../../pages/pre-built/user-manage/UserContext";
import Profile from "../Profile";

//^ components
import ErrorModel from "../../components/ui/modals/error-model/ErrorModel";
import { getSubscriptedPackageHandler } from "../../http/post-api";
import ErrorAlert from "../../components/ui/modals/error-model/ErrorAlert";
import OutletSkeleton from "../../components/ui/loader/Skeleton/OutletSkeleton";

export default function UserLayoutApp({ title, app }) {
  const muiTheme = useSelector((state) => state.mui.theme);

  const theme = useTheme();
  const MUITheme = useMuiTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useContext(UserContext);

  //^ state
  const [data, setData] = useState(loggedInUser);
  const [sm, updateSm] = useState(false);
  // eslint-disable-next-line
  const [authChecking, setAuthChecking] = useState(true);
  const [mobileView, setMobileView] = useState(false);

  //& Error States
  const [userProfileErrorModel, setUserProfileErrorModel] = useState(false);

  //^ getting subscribed package query
  const {
    data: subscribedPackageData,
    isLoading: subscribedPackageIsLoading,
    isError: subscribedPackageIsError,
    error: subscribedPackageError,
    refetch: subscribedPackageRefetch,
  } = useQuery({
    queryKey: ["get-user-subscription-package-details"],
    queryFn: getSubscriptedPackageHandler,
  });

  useEffect(() => {
    if (subscribedPackageIsError) {
      console.log(subscribedPackageError);
    }
  }, [subscribedPackageError, subscribedPackageIsError]);

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#666" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  const {
    data: userProfileData,
    isLoading: userProfileIsLoading,
    isRefetching: userProfileIsRefetching,
    isError: userProfileIsError,
    error: userProfileError,
    refetch: userProfileRefetch,
  } = useQuery({
    queryKey: ["get-user-profile-user-layout"],
    queryFn: ({ signal }) => getUserProfileHandler({ signal }),
  });

  useEffect(() => {
    if (!userProfileIsLoading || !userProfileIsRefetching) {
      if (userProfileData?.status) {
        dispatch(userProfileAction.setWholeProfileData({ wholeProfileData: userProfileData?.data }));
        dispatch(userProfileAction.setProfileData({ profileData: userProfileData?.data.profile }));
      } else {
        if (userProfileData?.redirect) {
          navigate("/login");
        }
      }
    }
    // eslint-disable-next-line
  }, [userProfileIsLoading, userProfileIsRefetching, userProfileData, navigate]);

  useEffect(() => {
    if (userProfileIsError) {
      setUserProfileErrorModel(userProfileIsError);
    } else {
      setUserProfileErrorModel(userProfileIsError);
    }
  }, [userProfileIsError, userProfileError]);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  //^ Initial useEffect
  useEffect(() => {
    const cookieValue = Cookies.get("authToken");
    if (!cookieValue) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
    }

    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [navigate]);

  return (
    <>
      {subscribedPackageIsError && (
        <ErrorAlert
          title={subscribedPackageError?.status}
          description={subscribedPackageError?.info?.message}
          isConformed={() => subscribedPackageRefetch()}
        />
      )}
      <ErrorModel
        isOpen={userProfileErrorModel}
        toggleOpen={() => toggleErrorModel(setUserProfileErrorModel, userProfileErrorModel)}
        title={"Something went wrong"}
        description={"Unable to verify otp!"}
        onConfirmed={() => {
          toggleErrorModel(setUserProfileErrorModel, userProfileErrorModel);
          userProfileRefetch();
        }}
      />
      <UserContext.Provider value={{ loggedInUser: data, setData }}>
        <Profile />
        <Head title={!title && "Loading"} />
        <AppRoot>
          <SimpleBar>
            <AppMain>
              <Sidebar
                updateSm={updateSm}
                sm={sm}
                mobileView={mobileView}
                subscribedPackageData={subscribedPackageData}
                fixed
              />
              <AppWrap>
                <AppHeader app={app} fixed />
                {subscribedPackageIsLoading ? (
                  <Box
                    sx={{
                      "@media (min-width: 65.5rem)": {
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                      },
                    }}
                  >
                    <Paper className="rounded-4 w-100 p-4 vh-100">
                      <OutletSkeleton />
                    </Paper>
                  </Box>
                ) : (
                  <div className={`${classes["layout-relative"]} mt-4 pb-4`}>
                    <Paper
                      sx={{
                        backgroundColor: "transparent",
                        padding: "0rem",
                        "@media (min-width: 65.5rem)": {
                          display: "none",
                        },
                      }}
                      className="rounded-4"
                      style={{ minHeight: "89vh", maxHeight: "auto" }}
                    >
                      <Box
                        sx={{
                          "@media (min-width: 65.5rem)": {
                            ...MUITheme.palette.cardInner.lg,
                          },
                        }}
                      >
                        {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)} />}
                        <BlockHead size="lg">
                          <BlockBetween>
                            <BlockHeadContent className={"w-100"}>
                              <Outlet />
                            </BlockHeadContent>
                          </BlockBetween>
                        </BlockHead>
                      </Box>
                    </Paper>
                    <Paper
                      sx={{
                        display: "none",
                        padding: "0rem",
                        "@media (min-width: 65.5rem)": {
                          display: "block",
                        },
                      }}
                      className="rounded-4"
                      style={{ minHeight: "89vh", maxHeight: "auto" }}
                    >
                      <Box
                        sx={{
                          "@media (min-width: 65.5rem)": {
                            ...MUITheme.palette.cardInner.lg,
                          },
                        }}
                      >
                        {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)} />}
                        <BlockHead size="lg">
                          <BlockBetween>
                            <BlockHeadContent className={"w-100"}>
                              <Outlet />
                            </BlockHeadContent>
                          </BlockBetween>
                        </BlockHead>
                      </Box>
                    </Paper>
                  </div>
                )}
              </AppWrap>
            </AppMain>
          </SimpleBar>
        </AppRoot>
      </UserContext.Provider>
    </>
  );
}
