export const navItems = [
  {
    to: `${process.env.PUBLIC_URL}/user-profile-regular`,
    icon: "user-fill-c",
    title: "Personal Information",
  },
  {
    to: `${process.env.PUBLIC_URL}/user-profile-notification`,
    icon: "bell-fill",
    title: "Notification",
  },
  {
    to: `${process.env.PUBLIC_URL}/subscription-package`,
    icon: "coin-alt-fill",
    title: "Package Subscription",
  },
  {
    to: `${process.env.PUBLIC_URL}/services`,
    icon: "view-list-fill",
    title: "Services",
  },
  {
    to: `${process.env.PUBLIC_URL}/user-profile-setting`,
    icon: "lock-alt-fill",
    title: "Security Settings",
  },
];
